/*
    Document breakpoints:
    - desktop > 1200px
    - medium 768px - 1200px
    - mobile 320px - 768px
*/

/* ================================= KMK TITLE TILE ================================= */
.kmk-title-tile {
    width: 100vw;
    height: 80vh;
    position: relative;
}

.kmk-title-tile .kmk-title-tile-container {
    overflow: hidden;
}

.kmk-title-tile-background {
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kmk-title-tile img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    will-change: transform;
}

.kmk-title-tile h1 {
    position: absolute;
    white-space: pre-wrap;
    bottom: 2rem;
    left: 2rem;
    font-family: 'Eksell', sans-serif;
}

.kmk-logo {
    position: fixed;
    width: 50px !important;
    height: 50px !important;
    top: 1rem;
    right: 1rem;
    z-index: 10000;
}

@media screen and (max-width: 768px) {
    .kmk-title-tile {
        height: 50vh;
        /*margin-top: 4rem;*/
    }
    .kmk-title-tile h1 {
        font-size: 24px;
        right: 2rem;
        bottom: unset;
        top: 2rem;
    }
    .kmk-title-tile img {
        height: 50vh;
    }
}

/* ================================= KMK TEXT TILE ================================= */

.kmk-text-tile {
    padding: 2rem;
    line-height: 1.5;
    display: flex;
    flex-flow: column;
    background-size: cover !important;
    background-position: center !important; 
    background-repeat: no-repeat !important;
}

.kmk-text-tile .with-minimal-height {
    min-height: 400px;
}

.kmk-text-tile h1 {
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}
.kmk-text-tile .no-margin h1 {
    margin-bottom: 0;
}

.kmk-text-tile h2 {
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

.kmk-text-tile p {
    line-height: 26px;
    font-size: 1rem;
    color: #171717;
    padding-bottom: 1rem;
    white-space: pre-wrap;
}

.p-offset {
    margin-top: 7.2rem;
}

@media screen and (max-width: 768px) {
    .kmk-text-tile { 
        padding-bottom: 2rem !important; 
        padding-top: 2rem !important; 
    }
    .kmk-text-tile p {
        padding-top: 1rem !important;
        line-height: 1.6;
    }
    .kmk-text-tile h1, p {
        padding: 0 !important;
    }
    .p-offset { margin-top: 0; }
}

/* ================================= KMK IMAGE TILE ================================= */

.kmk-image-tile {
    display: flex;
    flex-flow: row nowrap;
}

.kmk-image-tile div { 
    justify-content: inherit; 
    display: inherit; 
    width: 100%; 
}

.kmk-image-tile img { 
    height: auto; 
    object-fit: cover; 
    max-width: 100%;
    /* min-width: 100%; */
    height: 100%;
    box-sizing: border-box;
}
.stack-images-1, .stack-images-2, .stack-images-3 {
    justify-content: center !important;
}/*
.stack-images-1 {
    padding-bottom: .5rem;
}*/
.stack-images-1 img {
    /*  min-width: 100%; */
}
.stack-images-2 {
    overflow: hidden;
}
.stack-images-2 img {
    width: 50% !important;
}
.stack-images-2 img:nth-child(odd) {
    padding-right: .5rem;
}

.kmk-image-tile img[data-visible] { opacity: 1; }

@media screen and (max-width: 600px) {
    .kmk-image-tile div {
        flex-flow: column;
    }
    .kmk-image-tile {
        flex-flow: column;
        align-items: center;
    }
    .kmk-image-tile img {
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
    }
    .stack-images-1 {
        padding-right: 0;
    }
    .stack-images-2 img {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 600px) and (max-width: 769px) {
    .kmk-image-tile {
        flex-flow: row wrap;
        justify-content: center;
        height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .kmk-image-tile img {
        object-fit: cover !important;
        width: 100% !important;
    }
    .kmk-image-tile img[data-nomargin] { 
        margin: 0 !important;
    }
    .stack-images-1 {
        padding-right: 0;
    }
    .stack-images-1 img {
        padding: 0 !important;
    }
    .stack-images-2 {
        flex-flow: column !important;
        align-items: center;
        justify-content: unset !important;
        margin-bottom: 3rem;
    }
    .stack-images-2 img {
        margin: 0 !important;
        padding: 0 0 1rem 0 !important;
        object-fit: contain !important;
    }
    .stack-images-3 {
        flex-flow: column !important;
        align-items: center;
    }
    .stack-images-3 img:not([data-dontscale]) {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        object-fit: contain;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .kmk-image-tile {
        flex-flow: row wrap;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .kmk-image-tile img { 
        width: 100%;
        max-width: 100%;
        flex-grow: 2;
    }
    .stack-images-2 img {
        width: 100% !important;
        margin-bottom: .5rem;
    }
    .stack-images-3 {
        flex-flow: row nowrap !important;
    }
    .stack-images-3 img:not([data-dontscale]) {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        object-fit: cover;
    }
    .kmk-image-tile img[data-nomargin] { 
        margin: 0 !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
    .stack-images-2 {
        flex-flow: column;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) { 
    .stack-images-2 {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: unset;
    }
    .stack-images-2 img {
        margin: 0 !important;
        padding: 0 !important;
        width: 50% !important;
        padding: 0 .5rem !important;
        object-fit: cover !important;
    }
}

@media screen and (min-width: 1800px) {
    .kmk-image-tile img { 
        height: auto; 
        /* width: 100% !important; */
    }
}

.kmk-image-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: #FFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 2000ms ease;
}

.kmk-image-preview-delay { 
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
}

/* ================================= KMK LIST TILE ================================= */
.kmk-list-tile {
    display: flex;
    flex-flow: column;
    padding: 3rem 2rem 2rem 2rem;
}
.kmk-list-tile h1 {
    margin: 0 0 2rem 0;
    font-family: 'Eksell', sans-serif;
}
.kmk-list-tile h2 {
    line-height: 40px !important;
    margin-bottom: 1rem;
}
.kmk-list-tile p {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}
.kmk-list-tile ul {
    list-style-type: disc !important;
    padding-left: 1.5rem;
}
.kmk-list-tile ul li {
    margin-bottom: .5rem;
    font-size: 20px;
    line-height: 28px;
}
.kmk-list-tile label {
    margin-bottom: 2rem;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .kmk-list-tile h2 {
        font-size: 24px !important;
        line-height: 38px !important;
    }
    .kmk-list-tile ul li {
        font-size: 16px;
        line-height: 22px;
    }
}
@media screen and (max-width: 768px){
    .kmk-list-tile {
        padding: 2rem 2rem 0 2rem;
    }
}

/* ================================= KMK MEDIA LINK TILE ================================= */
.kmk-media-tile {
    padding: 2rem;
    line-height: 1.5;
}

.kmk-media-tile img {  width: 170px; height: 170px; max-width: 170px; border-radius: 10px; object-fit: cover; }

.kmk-media-tile a { display: inline-block; margin: 2rem 0; }

.kmk-media-tile p {
    font-size: 1rem;
    padding-bottom: 1rem;
}

/* ================================= KMK IMAGE TEXT TILE ================================ */
.kmk-image-text-tile {
    padding: 2rem;
}

@media screen and (max-width: 768px) {
    .kmk-image-text-tile {
        display: flex;
        flex-flow: column;
    }
    .kmk-image-text-tile-content p {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .kmk-image-text-tile-images img {
        width: 100%;
        padding-right: 2rem;
    }
    .image-align-right {
        flex-flow: column-reverse !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 2rem !important;
    }
    .image-align-left { flex-flow: column-reverse !important; }
    .image-align-left .kmk-image-text-tile-content { margin-bottom: 2rem; }
    .image-align-right .kmk-image-text-tile-content { margin-bottom: 2rem; padding: 0 !important; }
}

.kmk-image-text-tile-images {
    flex-grow: 2;
    flex-basis: 50%;
}
.kmk-image-text-tile-content {
    flex-grow: 1;
    flex-basis: 50%;
    padding-right: 2rem;
}

.kmk-image-text-tile-images img {
    width: 100%;
    height: auto; 
    object-fit: cover;
}

.kmk-image-text-tile-content h1 {
    margin-bottom: 4rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

.kmk-image-text-tile-content p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
}

.image-align-left {
    display: flex;
    flex-flow: row;
}

.image-align-right {
    display: flex;
    flex-flow: row-reverse;
}

.image-align-bottom {
    display: flex;
    flex-flow: column-reverse;
}

.image-align-left img { height: 100%; }

.image-align-right img { height: 100%; }

.image-align-left .kmk-image-text-tile-content { padding-left: 2rem; padding-right: 2rem; }
.image-align-right  { padding-left: 2rem; }

.image-align-bottom { padding-right: 2rem; }
.image-align-bottom img { height: 100%; }
.image-align-bottom .kmk-image-text-tile-content { margin-bottom: 2rem; padding-right: 2rem; }

/* ================================= KMK LINK EMBEDED TILE ================================ */
.kmk-link-embeded-tile {
    padding: 2rem;
}
.kmk-link-embeded-tile > * {
    line-height: 26px;
}
.kmk-link-embeded-tile * {
    line-height: 26px;
}
.kmk-link-embeded-tile h2 {
    padding-bottom: 1rem;
}
.kmk-link-embeded-tile h2 {
    padding-bottom: 1rem;
}
.kmk-link-embeded-tile blockquote {
    line-height: 33px;
    font-size: 1.5rem;
}
.kmk-link-embeded-tile a {
    text-decoration: underline;
}
.kmk-link-embeded-tile a:hover {
    font-weight: 800;
}
.kmk-link-embeded-tile p {
    line-height: 26px;
}
.kmk-link-embeded-tile pre {
    background-color: #292929;
    color: #fff;
    font-size: 13px;
    line-height: 1.3;
    padding: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.kmk-link-embeded-tile ul li {
    line-height: 1.5;
}
.kmk-link-embeded-tile ol li {
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .kmk-link-embeded-tile {
        padding-right: 2rem;
    }
    .kmk-link-embeded-tile p {
        line-height: 1.6;
    }
}

/* ================================= KMK PEOPLE TILE ================================ */
.kmk-people-tile {
    display: flex;
    flex-flow: column;
    padding: 1rem 4rem;
}

.kmk-people-tile img {
    height: auto;
    width: 100% !important;
    object-fit: cover;
    break-after: always;
}

.kmk-people-tile h2 {
    padding: 1rem 0;
    font-size: 24px;
    break-after: always;
    font-family: 'Eksell', sans-serif;
}

.kmk-people-tile p {
    padding: 1rem 0;
    padding-top: 0;
    font-weight: 300;
    color: #171717;
    line-height: 1.5;
    white-space: pre-wrap;
}

/* ================================= KMK CONTACT TILE ================================ */
.kmk-contact-tile {
    padding: 2rem;
}

.kmk-contact-tile h1 {
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    font-size: 24px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 17rem;
}

.kmk-contact-tile h2 {
    margin: 1rem 0;
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    font-size: 24px;
}

.kmk-contact-tile p {
    line-height: 26px;
    color: #171717;
    white-space: pre-wrap;
}

.kmk-contact-tile img {
    object-fit: contain;
    object-position: left;
    max-height: 400px;
}

@media screen and (min-width: 250px) and (max-width: 420px) {
    .kmk-contact-tile {
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        padding: 2rem;
        padding-left: 4rem;
    }
    .kmk-contact-tile img {
        object-fit: contain;
        object-position: left;
        height: 290px !important;
    }
}

@media screen and (min-width: 420px) and (max-width: 768px) {
    .kmk-contact-tile {
        width: 50% !important;
        max-width: 50% !important;
        flex-basis: 50% !important;
        padding: 2rem;
        padding-left: 4rem; 
    }
    .kmk-contact-tile img {
        object-fit: contain;
        object-position: left;
        object-position: center;
        height: 180px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 908px) {
    .kmk-contact-tile img {
        max-height: 200px;
        object-fit: contain;
        object-position: center;
    }
}

@media screen and (min-width: 908px) and (max-width: 2000px) {
    .kmk-contact-tile {
        padding: 2rem 4rem;
        max-width: 33% !important;
        flex-basis: 33% !important; 
    }
    .kmk-contact-tile img {
        max-height: 250px;
    }
}


/* ================================= KMK CAROUSEL TILE ================================ */
.kmk-carousel-tile {
    width: 100%;
    height: 95vh;
    min-height: 95vh;
    color: #333;
    background: #FFF;
}

.kmk-carousel-image {
    height: 95vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kmk-carousel-html {
    padding: 40px;
    padding-left: 4rem;
    outline: none;
    user-select: none;
}

.kmk-carousel-html p {
    font-family: 'Eksell', sans-serif;
    font-size: 3rem;
}

.kmk-carousel-html a {
    font-size: 1.25rem;
}

.kmk-carousel-tile img {
    width: 100%;
    height: 100%;
}

.kmk-carousel-image-link {
    user-select: none;
    outline: none;
}

.kmk-carousel-image-link h1 {
    font-size: 1.2rem;
    padding: 1rem 0;
    margin-left: 1rem;
    font-family: 'Eksell', sans-serif;
}

.kmk-carousel-image-link a {
    margin-left: 1rem;
    font-size: .8rem;
}

.kmk-carousel-image-link-background {
    outline: none;
    user-select: none;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* ================================= KMK FOOTER TILE ================================ */
.kmk-footer-tile {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.kmk-footer-tile-caption {
    width: 100%;
    height: 54px;
    color: #FFF;
    font-weight: 600;
    background-color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left: 63px;
}
.kmk-footer-tile-container {
    background: #FFF;
    color: #000000;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}
.kmk-footer-tile .icons-container {
    padding-top: 1rem;
    display: flex;
}
.kmk-footer-tile .icons-container img {
    width: 35px;
    height: 35px;
    margin-right: 17px;
    cursor: pointer;
}
.kmk-footer-tile > section > * {
    font-weight: 700;
}
.kmk-footer-tile-section {
    padding: 2rem;
    padding-left: 4rem;
    flex-grow: 1;
    flex-basis: 25%;
}
.kmk-footer-tile-section > div > a {
    margin-bottom: 10px;
    line-height: 1.35;
    font-weight: 600;
    display: block;
}
.kmk-footer-tile-section .section-part {
    display: flex;
    flex-flow: column;
    margin-bottom: 2rem;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper {
    position: relative;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper button {
    position: absolute;
    top: 20px;
    right: 5px;
    width: 35px;
    height: 35px;
    padding: unset !important;
    border-radius: 50%;
    transition: all 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper .submitted {
    background: #4B8700;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper .submitted img {
    position: absolute;
    top: 11px;
    right: 9px;
    width: 15px;
    height: 15px;
}
.kmk-footer-tile-newsletter .newsletter-error {
    margin-bottom: 5px;
}
.kmk-footer-tile-newsletter .newsletter-label {
    white-space: nowrap;
    display: block;
    text-align: left;
}
.kmk-footer-tile-newsletter .newsletter-input {
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #000;
    background-color: #FFF;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 15rem;
    width: 100%;
    color: #000;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input::placeholder {
    color: #000 !important;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input:-moz-placeholder {
    color: #000 !important;
}
.kmk-footer-tile .kmk-checkbox > * {
    font-weight: 400;
}
.kmk-footer-tile-policy-link {
    padding-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .kmk-footer-tile-container {
        flex-flow: row wrap;
    }
    .kmk-footer-tile-section {
        width: 38%;
        flex-basis: 38%;
    }
}

@media screen and (max-width: 768px) {
    .kmk-footer-tile-section { padding-left: 2rem; }
    .kmk-footer-tile-section .section-part label { font-size: 1.2rem; }
    .kmk-footer-tile-container { flex-flow: column; }
    .kmk-footer-tile .kmk-footer-tile-newsletter .newsletter-label {
        text-align: left;
    }
    .kmk-footer-tile-caption {
        padding: 0;
        justify-content: center;
        font-size: 12px;
    }
}

/* ================================= KMK CTA TILE ================================ */
.kmk-cta-tile {
    display: flex;
    padding: 4rem 4rem;
    background: #F6F6F6;
}

.kmk-cta-tile .cta-section {
    flex-basis: 50%;
    flex-grow: 2;
    width: 50%;
    padding-right: 1rem;
}

.kmk-cta-tile .cta-section h2 { 
    font-family: 'Eksell', sans-serif;
    font-weight: 600;
    white-space: pre-wrap;
}

.kmk-cta-tile .cta-section p {
    font-weight: 300;
    color: #171717;
    line-height: 1.5;
    padding-top: .25rem;
    margin-bottom: 2rem;
    white-space: pre-wrap;
}

.kmk-cta-tile .cta-section a {
    font-family: 'Eksell', sans-serif;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .kmk-cta-tile {
        flex-flow: column;
    }
    .kmk-cta-tile .cta-section { flex-basis: 100%; width: 100%; }
}

/* ================================= KMK MAP TILE ================================ */
.kmk-map-tile {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 2rem;
}

.kmk-map-tile h1 {
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
}

.kmk-map-tile h2 {
    color: #1E1B1B;
    margin-top: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

@media screen and (max-width: 768px) {
    .kmk-map-tile {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
    .kmk-map-tile h1 {
        padding-left: 4rem;
        padding-right: 2rem;
    }
    .kmk-map-tile h2 {
        padding-left: 4rem;
        padding-right: 2rem;
    }
}

/* ================================= KMK Page Navigator ================================= */

.kmk-page-navigator {
    position: absolute;
    top: 115vh;
    cursor: pointer !important;
    z-index: 1000;
    transform-origin: center;
}

.kmk-page-navigator a:after { content: none; }
.kmk-page-navigator a:before { content: none; }

.kmk-page-navigator a label { cursor: pointer; white-space: nowrap; }

.kmk-page-navigator a label:first-child {
    color: rgb(240, 57, 25);
    padding-right: 1rem;
    position: relative;
}

.kmk-page-navigator a label:first-child:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: rgb(240, 57, 25);
    border-radius: 50%;
    top: calc(50% + 2px);
    right: 4px;
    
}

.navigator-next a {
    transform: rotate(90deg);
    position: absolute;
    right: calc(-100vw - 2rem);
    max-width: 500px;
}

.navigator-prev a {
    transform: rotate(-90deg);
    position: absolute;
    left: -1.5rem;
}

.navigator-sticky {
    position: fixed;
    top: 48vh;
}

/* ================================= KMK Video Tile ================================= */
.kmk-video-tile {
    padding: 2rem;
    min-height: 480px;
    height: 480px;
    overflow: hidden;
}

.kmk-video-tile div {
    width: 100%;
    height: 100%;
}

.kmk-video-tile iframe {
    width: 100%;
    height: 100% !important;
}
.kmk-video-tile video {
    width: 100%;
    height: inherit;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .kmk-video-tile {
        min-height: 400px;
        height: 400px;
        overflow: hidden;
    }
    .kmk-video-tile video {
        width: initial;
    }
}

/* ================================= KMK Card Tile Component ================================= */
.kmk-card-tile {
    padding: 0 2rem;
}

.kmk-card-tile h1 {
    overflow: hidden;
    text-overflow: ellipsis;
}

.kmk-card-tile h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin: 1.2rem 0;
}
.kmk-card-tile p {
    line-height: 1.5;
}
.kmk-card-tile .card-tile-container {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}
.kmk-card-tile .card-tile-container > * {
    padding-left: 0 !important;
}
.kmk-card-tile .card-tile-container a {
    text-decoration: underline;
}
.kmk-card-tile .center-container {
    align-items: center;
    justify-content: center;
    padding-right: 0 !important;
}
.kmk-card-tile .center-container .card-tile-container {
    padding-right: 0;
    padding-left: 0;
}
.kmk-card-tile .no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.kmk-card-tile .kmk-card-tile-link {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.kmk-card-tile .kmk-card-tile-link .kmk-card-tile-link-images {
    overflow: hidden;
}
.kmk-card-tile img {
    object-fit: cover;
    max-height: 790px;
    transition: transform 400ms ease;
    will-change: transform;
}
.kmk-card-tile-link[href] {
    cursor: pointer;
}
.kmk-card-tile-link[href]:hover img {
    transform: scale(1.05);
}
.kmk-card-tile-link:before, .kmk-card-tile-link:after {
    content: none !important;
}

.kmk-card-tile-link[href] .kmk-card-tile-images img {
    will-change: transform;
    transition: transform 500ms ease;
}

.kmk-card-tile-link:hover[href] .kmk-card-tile-images img {
    transform: scale(1.1);
}

.kmk-card-tile-link:hover[href] .card-tile-container a {
    font-weight: 600;
}

.kmk-card-tile-link:not([href]) { cursor: default; }

@media screen and (min-width: 1600px) and (max-width: 2000px) {
    .kmk-card-tile-link-images {
        max-height: 700px;
    }
    .kmk-card-tile img {
        max-height: 700px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .kmk-card-tile-link-images {
        max-height: 590px;
    }
    .kmk-card-tile img {
        max-height: 590px;
    }
}
@media screen and (min-width: 1224px) and (max-width: 1400px) {
    .kmk-card-tile-link-images {
        max-height: 512px;
    }
    .kmk-card-tile img {
        max-height: 512px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1224px) {
    .kmk-card-tile-link-images {
        max-height: 425px;
    }
    .kmk-card-tile img {
        max-height: 425px;
    }
}

@media screen and (max-width: 768px) {
    .kmk-card-tile {
        display: flex;
        flex-flow: column;
    }
    .kmk-card-tile .center-container .card-tile-container {
        padding-left: 0;
        padding-right: 0;
    }
    .kmk-card-tile .center-container img {
        max-height: 450px;
        object-fit: contain;
    }
    .kmk-card-tile .no-padding {
        padding: 0 !important;
    }
    .kmk-card-tile p {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .kmk-card-tile img {
        width: 100%;
        height: auto !important;
        /*padding-right: 2rem;*/
    }
    .image-align-right {
        flex-flow: column-reverse !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 2rem !important;
    }
    .image-align-left { flex-flow: column-reverse !important; }
    .image-align-left .kmk-image-text-tile-content { margin-bottom: 2rem; }
    .image-align-right .kmk-image-text-tile-content { margin-bottom: 2rem; padding: 0 !important; }
}

/* ================================= KMK Hamburger ================================= */

.kmk-menu-hamburger {
    position: fixed;
    top: 1rem;
    left: 1rem;
}

/* ================================= KMKBlogTile ================================= */
.kmk-blog-tile {
    padding: 2rem 2rem 0 2rem;
}

.kmk-blog-tile h1 {
    /* font-size: 3.05rem; */
    font-family: 'Eksell', sans-serif;
    white-space: pre-wrap;
}
.kmk-blog-summary {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    line-height: 1.5;
    font-weight: 600;
}
.kmk-blog-summary span {
    margin-right: 20px;
}
.kmk-blog-summary img {
    margin-right: 15px;
}
.kmk-blog-summary .avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
}
.kmk-blog-tag {
    text-transform: uppercase;
    margin-right: 5px;
}
.kmk-blog-tag:hover {
    text-decoration: underline;
}

/* ================================= KMKBlogTile ================================= */
.kmk-summary-tile {
    min-height: 400px;
    display: flex;
    flex-flow: row nowrap;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.kmk-summary-tile .kmk-summary-tile-section {
    width: 50%;
    flex-basis: 50%;
}
.kmk-summary-tile .kmk-summary-tile-text-section {
    padding: 2rem;
    line-height: 1.5;
}
.kmk-summary-tile .kmk-summary-tile-section img {
    height: 100%;
    object-fit: cover;
}
.kmk-summary-tile h2 {
    margin-bottom: 1rem;
    white-space: pre-wrap;
    font-size: 3.5rem;
    font-weight: 300;
    font-family: 'Eksell' !important;
}
.kmk-summary-tile p {
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.kmk-summary-tile .kmk-summary-tile-text-section {
    margin: 2rem 0;
    padding-right: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
    margin-left: 2rem;
    margin-top: 4.5rem;
}
.kmk-summary-tile .kmk-summary-tile-buttons-container button {
    margin-top: 1rem;
}
.kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
    margin-top: 7rem;
    margin-left: 2rem;
}
.kmk-summary-tile .kmk-summary-tile-image-section {
    position: relative;
}
.buttons-absolute {
    position: absolute;
    top: 7rem;
    left: 2rem;
    margin-top: 0 !important;
    margin-left: 0 !important;
}
.kmk-summary-tile .button-align-right {
    margin-right: 3rem;
    text-align: right;
}
.kmk-summary-tile .button-align-center {
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.kmk-summary-tile .button-align-left {
    text-align: left;
}
.kmk-summary-tile .button-align-center {
    text-align: center;
}
.kmk-summary-tile .kmk-summary-tile-section .kmk-summary-tile-buttons-container .react-ripples {
    margin-top: 10px;
}
.kmk-summary-tile .kmk-summary-tile-section button {
    display: inline;
    padding: 15px 30px;
    border-radius: 2rem;
    white-space: nowrap;
    font-size: 1rem;
    overflow: hidden;
    font-family: 'Eksell', sans-serif;
}
.kmk-summary-tile .has-images {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 !important;
    padding: 0 !important;
    transform: translate(-50%, -50%);
}
/*.kmk-summary-tile-buttons-container {
    margin-top: 6rem;
    margin-left: 8rem;
}*/

@media screen and (max-width: 768px) {
    .kmk-summary-tile {
        display: flex;
        flex-direction: column;
    }
    .additional-margin {
        padding-top: 6rem !important;
    }
    .kmk-summary-tile .kmk-summary-tile-section {
        width: 100%;
        flex-basis: 100%;
    }
    .kmk-summary-tile .kmk-summary-tile-section img {
        min-height: 60vh;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section {
        padding-left: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section {
        margin-top: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section .kmk-summary-tile-buttons-container {
        padding-top: 2rem;
        padding-bottom: 0;
        padding-left: 0;
        margin-left: 0 !important;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
        padding-left: 0rem;
        margin-left: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section .kmk-summary-tile-buttons-container button:last-child {
        margin-bottom: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
        margin-left: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-section button {
        margin-top: 4rem;
    }
    .kmk-summary-tile .kmk-summary-tile-buttons-container {
        margin-top: 0rem;
        margin-left: 4rem;
        padding-bottom: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-buttons-container button {
        margin-top: 0;
        margin-bottom: 2rem;
    }
}

/* ================================= Form ====================================== */
.kmk-form-tile {
    padding: 2rem;
    padding-right: 8rem;
}
.kmk-form-post-message {
    font-size: 14px;
    line-height: 1.5;
    padding: .5rem 0;
}
.kmk-form-tile h2 {
    font-size: 3.05rem;
    max-width: 60%;
    margin-bottom: 2rem;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
}

.form-input {
    width: 100%;
    margin-bottom: 1rem;
}
.form-input .form-input-error {
    margin-top: .2rem;
    color: #FF003A;
    font-size: 11px;
    display: block;
    text-align: left;
}
.form-input input {
    background-color: transparent;
    padding: 8px 0;
    display: block;
    width: 100%;
    color: #FFF;
    font-size: 16px;
    border-bottom: 3px solid #FFF;
    transition: all 400ms ease-in-out;
}
.form-input input::placeholder {
    color: #FFF;
}
.form-input[data-error="true"] input {
    color: #FF003A;
    border-bottom: 3px solid #FF003A;
    margin-bottom: .5rem;
}
.form-input[data-error="true"] input::placeholder {
    color: #FF003A;
}
.form-input textarea {
    min-height: 80px;
    padding: 13px 20px;
    color: #000;
    font-size: 16px;
    background-color: #FFF;
    margin-bottom: 0;
}
.form-input[data-error="true"] textarea {
    background-color: #FF003A;
    color: #FFF;
    margin-bottom: .5rem;
    transition: all 400ms ease-in-out;
}
.form-submit-button-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 2rem;
    margin-top: -.5rem;
}
.form-submit-button-container .form-submit-button {
    color: #FFFFFF;
    opacity: 1;
    padding: 0;
}
.form-submit-button-container .form-submit-button:hover { color: #FFF !important; opacity: 1 !important; }

.kmk-form-textarea {
    border: 1px solid #000;
    background-color: #FFF;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    min-height: 100px;
    padding: 15px;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
    outline: none;
    box-sizing: border-box;
}
.kmk-form-textarea::placeholder {
    color: #000;
}
.kmk-form-validation-error {
    color: #F00C21;
    font-size: 12px;
    display: block;
    margin-top: 5px;
    text-align: right;
}
.kmk-form-validation-error:last-child {
    margin-bottom: 20px;
}
.kmk-form-submit-button {
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 1rem;
    color: #FFF;
    transition: all 600ms ease;
}
.kmk-form-submit-button .check {
    width: 18px;
    height: 12px;
}
.submitted {
    background-color: #4B8700;
}

@media screen and (max-width: 768px) {
    .kmk-form-tile {
        padding-right: 2rem;
    }
    .kmk-form-tile h2 {
        max-width: 100%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1350px) {
    .kmk-form-tile h2 {
        max-width: 90%;
    }
}


/* =========================== Radiobutton ============================= */
.kmk-radiobutton-container {
    margin-top: 2rem;
}
.kmk-radiobutton-label{
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: left;
    display: block;
}
.kmk-radiobutton {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    text-align: left;
    outline: none;
}
.kmk-radiobutton {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.kmk-radiobutton input:focus, input:hover, input:active {
    outline: none;
}
.kmk-radiobutton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.kmk-radiobutton span {
    position: absolute;
    top: -5px;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 3px solid #F00C21;
}
.kmk-radiobutton span:after {
    content: "";
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    user-select: none;
    background-color: #F00C21;
}
.kmk-radiobutton input:checked ~span:after {
    display: block;
}

@media screen and (max-width: 768px) {
    .kmk-radiobutton:hover input:not(:checked) ~span:after {
        display: block;
        opacity: .5;
    }
    .kmk-form-tile h2 {
        max-width: 100%;
    }
}

.kmk-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
    text-align: left;
    outline: none;
    width: calc(100% - 2rem);
    line-height: 1.5;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.kmk-checkbox .kmk-checkbox-label {
    line-height: 1.7;
}
.kmk-checkbox a {
    text-decoration: underline;
}
.has-error {
    color: #F00C21;
}
.has-error span {
    border: 1px solid #F00C21 !important;
}
.kmk-checkbox input:focus, input:hover, input:active {
    outline: none;
}
.kmk-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.kmk-checkbox span {
    position: absolute;
    top: 5px;
    left: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #000;
}
.kmk-checkbox span:after {
    content: "";
    display: none;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    user-select: none;
    background-image: url('../assets/icons/checkbox.svg');
}
.kmk-checkbox input:checked ~span:after {
    display: block;
}

/* ================================= Buttons Tile ====================================== */

.kmk-buttons-tile {
    padding: 2rem;
}
.kmk-buttons-tile .basic-button {
    padding: 15px 30px;
    border-radius:  30px;
    font-size: 1rem;
    position: relative;
}
.kmk-buttons-tile .with-icon {
    padding-left: 55px;
}
.kmk-buttons-tile .basic-button img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
}

@media screen and (max-width: 768px) {
    .kmk-buttons-tile {
        display: flex;
        flex-flow: column;
        padding: 2rem;
    }
    .kmk-buttons-tile .basic-button {
        margin-bottom: 1rem;
        margin-left: 0 !important;
        margin-right: 1rem;
    }
}

/* ================================= Table of contents ================================= */

.kmk-table-of-contents {
    padding: 2rem;
    font-weight: 600;
    position: relative;
}
.kmk-table-of-contents a {
    line-height: 26px;
    font-weight: 400;
    text-decoration: underline;
}
.kmk-table-of-contents a:hover {
    font-weight: 800;
}
.column-layout {
    display: flex;
    flex-direction: row nowrap;
}
.column-layout div:last-child {
    margin-left: 4rem;
    margin-top: 3rem;
}
@media screen and (max-width: 768px) {
    .column-layout { flex-flow: column; }
    .column-layout div {
        margin: 0 !important;
    }
}

/* ================================= Instagram ================================= */

.kmk-instafeed {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
}
.kmk-instafeed a:nth-child(even) {
    margin: 0 15px;
}
.instagram-feed-tile {
    width: 300px;
    min-width: 300px;
    border: 1px solid #EBECF0;
    border-radius: 6px;
}
.instagram-feed-tile .instagram-feed-tile-header {
    display: flex;
    flex-flow: row nowrap;
    height: 60px;
    align-items: center;
    padding: 10px;
}
.instagram-feed-tile .instagram-feed-tile-header .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.instagram-feed-tile-header .instagram-logo {
    width: 25px;
    height: 25px;
}
.instagram-feed-tile .empty-grow {
    flex-grow: 2;
}
.instagram-feed-tile-header .feed-details {
    display: flex;
    flex-flow: column;
    margin-left: 20px;
}
.instagram-feed-tile-header .feed-details .full-name {
    color: #000;
    font-size: 14px;
}
.instagram-feed-tile-header .feed-details .post-date {
    color: #C1C7D0;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
}
.instagram-feed-tile .feed-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.instagram-feed-tile .feed-manage-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 10px;
}
.instagram-feed-tile .feed-manage-bar .feed-manage-section {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    cursor: pointer;
    margin-right: 20px;
}
.instagram-feed-tile .feed-manage-bar .feed-manage-section img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.instagram-feed-tile .feed-content {
    padding: 0 10px 20px 10px;
    margin-bottom: 25px;
    overflow: hidden;
    /*  height: 225px; */
}
.instagram-feed-tile .feed-content .caption {
    margin-right: 10px;
    overflow-wrap: break-word;
}

/* =================================== Appointly ==================================== */
.kmk-appointly-tile iframe {
    width: 100%;
    min-height: 600px;
}

@media screen and (max-width: 768px) {
    .kmk-instafeed {
        flex-flow: column;
        align-items: center;
    }
    .kmk-instafeed a:nth-child(odd) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.kmk-top-bar-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: 'Eksell', sans-serif;
    opacity: 0;
    cursor: pointer;
    animation: fade-block 500ms ease 500ms forwards;
}
@media screen and (max-width: 768px) {
    .kmk-top-bar-title {
        display: none;
    }
}

/* ================================= ANIMATIONS ================================= */

.show-block-pre { opacity: 0; }
.show-block {
    animation: show-block 1600ms ease forwards;
}

.fade-block-pre { 
    opacity: 0; 
    height: 100%; 
    width: 100%;
    display: flex;
    flex-flow: inherit;
    padding: inherit;
    margin: inherit;
    justify-content: inherit;
    align-items: inherit;
}
.fade-block {
    animation: fade-block 1600ms ease forwards;
}

@keyframes fade-block {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes show-block {
    0% { opacity: 0; transform: translateY(20%); }
    100% { opacity: 1; transform: translateY(0); }
}
