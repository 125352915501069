.search-page-container {
    padding: 6rem 3rem;
}
.search-results-container {
    padding: 2rem 0 0 0;
    display: flex;
    flex-flow: row wrap;
}
.latest-articles-container {
    display: flex;
    flex-flow: row nowrap;
}
@media screen and (max-width: 767px) {
    .latest-article-tile {
        width: 100% !important;
        margin: 0 !important;
        margin-top: 10px !important;
    }
    .latest-article-tile h2 {
        padding: 15px 0 15px 0 !important;
    }
    .latest-article-tile .text-container > div {
        padding-bottom: 20px;
        line-height: 1.5 !important;
    }
    .latest-articles-container {
        flex-flow: column;
    }
    .latest-articles-container .latest-article-tile {
        margin-bottom: 40px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/* Latest articles */
.latest-articles-label {
    font-weight: 600;
    margin-bottom: 30px;
}
.latest-article-tile {
    width: 31%;
    margin-bottom: 40px !important;
    cursor: pointer;
}
.latest-article-tile .image-container {
    width: 100%;
    min-height: 250px;
    height: 250px;
    overflow: hidden;
}
.latest-article-tile .image-container img {
    object-fit: cover;
    width: 100%;
    height: 250px;
    transition: transform 300ms ease;
}
.latest-article-tile .text-container h2 {
    font-weight: 300;
    padding: 24px 0 14px 0;
}
.latest-article-tile .text-container h2 > * {
    font-weight: 600;
}
.latest-article-tile .text-container .latest-article-tile-h2 > * {
    font-family: 'Eksell', sans-serif !important;
    font-weight: 300 !important;
}
.latest-article-tile .text-container > div > * {
    line-height: 1.5 !important;
}
.latest-article-tile .text-container .article-details {
    font-size: .8rem;
    font-weight: 600;
    margin-top: 10px;
}
.latest-article-tile .text-container .article-details .author {
    margin-right: 1rem;
}
.latest-article-tile .text-container .article-details .tag {
    padding: 0 3px;
    text-transform: capitalize;
}
.latest-article-tile .text-container .article-details .date {
    margin-left: 1rem;
}

@media screen and (min-width: 768px) {
    .latest-article-tile .image-container:hover img {
        transform: scale(1.05);
    }
}