html,
body,
nav,
input,
div {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1;
    font-weight: 400;
    box-sizing: border-box;
}
*:after, *:before {
    content: '';
    position: absolute;
    box-sizing: inherit;
    text-decoration: inherit;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input {
    border: none;
    outline: none;
    box-sizing: border-box;
}
button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}
a {
    outline: none;
    text-decoration: none;
    border: none;
    color: inherit;
}
a:link, a:visited, a:hover, a:active{
    color: inherit;
}
html {
    min-width: 320px;
}
body { 
    background: #FFF;
    margin: auto;
}
p {
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4{
    /*color: #000;*/
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    min-width: 12rem;
    display: block;
}
