/* ======= ANIMATIONS ====== */
@keyframes slide-in-left {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slide-in-bottom {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* ======= MISC ====== */
.animated-content {
    animation: slide-in-bottom 2000ms ease forwards;
}
.col-6 { position: relative; }
.overlay-logo {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    z-index: 100;
    width: 65px !important;
    height: 65px;
    max-height: 65px;
    max-width: 60px !important;
    animation: fade-in 1s ease-in-out forwards;
}
.overlay-logo img { min-height: 60px !important; }
.kmk-image-tile .stack-images-1 .overlay-logo img { min-height: 60px !important; }
@media screen and (max-width: 768px) {
    .overlay-logo {
        bottom: 1rem;
        right: 1rem;
        width: 45px !important;
        height: 45px;
        max-height: 45px;
        max-width: 45px;
    }
    .overlay-logo img {
        width: 45px !important;
        min-height: 45px !important;
    }
}

/* ======= AF HEADER ======= */
.af-header {
    width: 100vw;
    /*max-height: 80vh;*/
}
.af-header .af-header-background {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
.af-header .af-header-background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-header .text-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
    max-width: 48%;
    margin-right: 36px;
    animation: slide-in-left 2000ms ease forwards;
}
.af-header .image-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
}
.af-header .image-container img {
    max-width: unset;
    max-height: 400px;
    position: relative;
}
.af-header .image-container .slick-slider {
    max-height: 400px;
    max-width: 800px;
}
.af-header .image-container .slick-slider img {
    object-fit: contain !important;
}
.af-header h1 {
    color: #FFF;
    font-weight: 800;
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.af-header p {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 28px;
    white-space: pre-wrap;
}
@media screen and (max-width: 500px) {
    .af-header h1 { line-height: 35px !important; }
    .af-header p { line-height: 24px !important; }
}
@media screen and (max-width: 400px) {
    .af-header .text-container { margin-right: 0; }
}
@media screen and (max-width: 800px) {
    .af-header .image-container { display: none; }
    .af-header .af-header-background .text-container { max-width: 500px; }
}
@media screen and (max-width: 1025px) {
    .af-header { height: unset; }
    .af-header .af-header-background { padding-top: 7rem !important; }
    .af-header .af-header-background .text-container { margin-bottom: 1rem; }
    .af-header .af-header-background { flex-flow: row wrap; padding: 2rem; }
    .af-header .af-header-background .text-container h2 { font-size: 30px; }
    .af-header .af-header-background .text-container p { font-size: 16px; }
}
@media screen and (min-width: 900px) and (max-width: 1150px) {
    .af-header .image-container .slick-slider {
        max-width: 500px;
    }
}
@media screen and (min-width: 1030px) and (max-width: 1550px) {
    .af-header .af-header-background .text-container {
        min-width: 40vw;
        padding: 8rem 0rem 6rem 0rem;
    }
    .af-header .image-container { max-width: 50vw; }
}
@media screen and (min-width: 1550px) {
    .af-header { height: 70vh; }
}


/* ======= AF HEADER HOVER ======= */
.af-header-hover {
    width: 100vw;
}
.af-header-hover .af-header-background {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
.af-header-hover .mouse-detector {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    z-index: 1;
}
.af-header-hover .af-background-layer-top {
    opacity: 0;
    transition: opacity 500ms ease;
}
.af-header-hover .af-background-layer-top[data-active="true"] {
    opacity: 1;
}
.af-header-hover .af-header-background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-header-hover .text-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
    max-width: 48%;
    margin-right: 36px;
    animation: slide-in-left 2000ms ease forwards;
}
.af-header-hover h1 {
    color: #FFF;
    font-weight: 800;
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.af-header-hover p {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 28px;
    white-space: pre-wrap;
}
.af-header-hover .logo {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    min-width: 30px;
    min-height: 30px;
    z-index: 100;
}
@media screen and (max-width: 500px) {
    .af-header-hover h1 { line-height: 35px !important; }
    .af-header-hover p { line-height: 24px !important; }
}
@media screen and (max-width: 400px) {
    .af-header-hover .text-container { margin-right: 0; }
}
@media screen and (max-width: 800px) {
    .af-header-hover h1 { white-space: normal; }
    .af-header-hover .af-header-background .text-container { max-width: 500px; }
}
@media screen and (max-width: 1025px) {
    .af-header-hover { height: unset; }
    .af-header-hover .af-header-background { padding-top: 7rem !important; }
    .af-header-hover .af-header-background .text-container { margin-bottom: 1rem; }
    .af-header-hover .af-header-background { flex-flow: row wrap; padding: 2rem; }
    .af-header-hover .af-header-background .text-container h2 { font-size: 30px; }
    .af-header-hover .af-header-background .text-container p { font-size: 16px; max-width: 55%; }
}
@media screen and (min-width: 1030px) and (max-width: 1550px) {
    .af-header-hover .af-header-background .text-container {
        min-width: 40vw;
        padding: 8rem 0rem 6rem 0rem;
    }
}
@media screen and (min-width: 1550px) {
    .af-header-hover { height: 70vh; }
}

/* ======= AF WORLDWIDE ======= */
.af-worldwide {
    display: flex;
    padding: 4rem 2rem;
}
.af-worldwide .list-container, .map-container {
    display: flex;
    align-items: center;
}
.af-worldwide .list-container, .map-container img {
    width: 700px;
}
.af-worldwide .list-container {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 800px;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    display: block;
}
.af-worldwide .list-container label {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 2rem;
    max-width: 60%;
    line-height: 28px;
}
.af-worldwide .map-container {
    flex-grow: 1;
    flex-basis: 50%;
    width: 700px;
    min-width: 700px;
    max-width: 700px;
    height: 700px;
    min-height: 700px;
    max-height: 700px;
    justify-content: center;
    position: relative;
}
.af-worldwide .list-container ul {
    list-style-type: none;
    margin: 0 !important;
    margin-top: 2rem !important;
    padding: 0;
}
.af-worldwide .list-container ul li {
    font-size: 42px;
    font-weight: 800;
    white-space: nowrap;
    position: relative;
    line-height: 55px;
    cursor: pointer;
}
.af-worldwide .list-container ul li span { position: relative; }
.af-worldwide .list-container ul li span:after {
    position: absolute;
    bottom: -.2rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 500ms ease;
}
.af-worldwide .list-container ul li[data-active="true"] span:after {
    transform: scaleX(1);
}
.af-worldwide .map-container .map-marker-container {
    position: absolute;
}
.af-worldwide .map-container .map-marker-container .map-marker {
    width: 60px;
    height: 60px;
    opacity: .4;
    cursor: pointer;
    transition: opacity 500ms ease;
}
.af-worldwide .map-container .map-marker-container .map-marker:hover {
    opacity: .5;
}
.af-worldwide .map-container .map-marker-container .map-marker-info {
    transform: translateY(10%);
    opacity: 0;
    position: absolute;
    top: 100%;
    min-width: 200px;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
}
.af-worldwide .map-container .map-marker-container[data-active="true"] .map-marker-info {
    transform: translateY(0);
    opacity: 1;
}
.af-worldwide .map-container .map-marker-container[data-active="true"] .map-marker {
    opacity: 1;
}
.origin-left { left: 0; }
.origin-right { left: unset !important; right: 0 !important; }
@media screen and (min-width: 768px) and (max-width: 1025px) {
    .af-worldwide .list-container label {
        font-size: 16px;
        max-width: 100%;
    }
}
@media screen and (max-width: 920px) {
    .af-worldwide {
        padding: 2rem;
        flex-flow: column;
        position: relative;
        max-height: 95vh;
    }
    .af-worldwide .list-container {
        max-width: 100%;
        padding-bottom: 1rem;
    }
    .af-worldwide .list-container label { font-size: 18px;}
    .af-worldwide .list-container ul li { font-size: 30px; line-height: 45px; }
    .af-worldwide .list-container ul li:after { height: 3px; }
    .af-worldwide .map-container {
        min-width: unset;
        transform-origin: left top;
        transform: scale(.75);
    }
}



/* ======= AF Card ======= */
.af-card {
    padding: 2rem;
}
.af-card .af-card-swiper {
    display: flex;
    position: relative;
}
.af-card .card-list {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    flex-basis: 50%;
    color: #FFF;
    margin-right: 2rem;
}
.af-card .card-list p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}
.af-card .card-list ul {
    margin: 1rem 0;
    padding: 0;
    list-style-type: none;
}
.af-card .card-list ul li {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    cursor: pointer;
}
.af-card .card-list ul li h2 { font-weight: 600; }
.af-card .card-list ul li span { position: relative; }
.af-card .card-list ul li span {
    text-decoration: none;
    background-image: linear-gradient(#FFF, #FFF);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 3px;
    transition: background-size .3s;
}
.af-card .card-list ul li[data-active="true"] span {
    /*transform: scaleX(1);*/
    background-size: 100% 3px;
}
.af-card .card-content {
    flex-grow: 1;
    flex-basis: 50%;
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    min-height: 265px;
    opacity: 1;
}
.af-card .card-content[data-animate="true"] {
    animation: show-card 500ms ease forwards;
}
@keyframes show-card {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 0;
        transform: translateY(10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .af-card { flex-flow: row; }
    .af-card .card-list p { font-size: 18px; }
    .af-card .card-list ul li { font-size: 30px; }
    .af-card .card-list ul li span:after { height: 3px; }
    .af-card .card-content {
        font-size: 18px;
    }
}
@media screen and (max-width: 767px) {
    .af-card .af-card-swiper { flex-flow: row wrap; padding-bottom: 4rem !important; margin-bottom: -4rem; }
    .af-card .card-list p { font-size: 16px; line-height: 24px; }
    .af-card .card-list ul li { font-size: 24px; line-height: 38px; }
    .af-card .card-list ul li span:after { height: 3px; }
    .af-card .card-content {
        margin-top: 1rem !important;
        font-size: 16px;
        line-height: 22px;
    }
    .af-card .card-list ul li[data-active="false"] {
        display: none !important;
    }
}




/* ======= AF CTA ======= */
.af-cta {}
.af-cta input[type="button"] {
    background-color: #000;
    color: #FFF !important;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 19px 41px;
    position: relative;
    transition: opacity 400ms ease-in-out;
}
.af-cta input[type="button"]:hover {
    opacity: 0.8;
}
.af-cta-wrapper {
    display: flex;
}
.af-cta-wrapper .text-container, .image-container {
    position: relative;
    flex-grow: 1;
    flex-basis: 50%;
    width: 100%;
}
.af-cta-wrapper .text-container, .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-cta-wrapper .text-container {
    padding: 4rem 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.af-cta-wrapper .text-container div { max-width: 80%; }
.af-cta-wrapper .text-container *[style*="font-size:42px"] { font-size: 42px; line-height: 55px !important; }
.af-cta-wrapper .text-container p { line-height: 1.3; }
.align-left { flex-flow: row-reverse; }
.align-right{ flex-flow: row; }
.af-cta-wrapper .text-container .buttons-container { margin-top: 2rem; }
.af-cta-wrapper .text-container .buttons-container button { white-space: nowrap; }

@media screen and (max-width: 400px) {
    .af-cta-background {
        padding: 6rem 0rem 4rem 2rem !important;
    }
}
@media screen and (max-width: 768px) {
    .af-cta-wrapper {
        flex-flow: column-reverse;
    }
    .af-cta-wrapper .text-container {
        padding: 3rem 2rem;
    }
    .af-cta-wrapper .image-container img {
        max-height: 400px;
    }
    .af-cta-wrapper .text-container .buttons-container { margin-top: 2rem; }
    .af-cta-wrapper .text-container p *[style*="font-size:42px"] { font-size: 30px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:22px"] { font-size: 18px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:20px"] { font-size: 16px !important; line-height: 22px !important; font-weight: 400 !important; }
    .af-cta-wrapper .text-container *[style*="line-height:28px"] { font-size: 16px !important; line-height: 22px !important; font-weight: 400 !important; }
    .af-cta-background .text-container p *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-cta-background .text-container *[style*="font-size:22px"] { font-size: 18px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:42px"] { line-height: 38px !important; }
    .af-cta-wrapper .text-container *[style*="line-height:48px"] { line-height: 38px !important; }
}
@media screen and (min-width: 1550px) {
    .af-cta-height-70 { height: 70vh; }
    .af-cta-height-70 .af-cta-wrapper { height: 100%; }
}
@media screen and (max-width: 768px) {
    .af-cta-height-70 .af-cta-wrapper {
        flex-flow: column;
    }
    .af-cta-height-70 .af-cta-wrapper .image-container img {
        max-height: 600px;
        min-height: 220px;
        object-fit: cover;
    }
}

/* ======= AF Html ======= */
.af-html {
    padding: 2rem;
}
.af-html .container-flex {
    display: flex;
}
.af-html .container-flex .container-left {
    margin-right: 4rem;
}
.af-html .container-flex .container-left, .container-right {
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-html input[type="button"] {
    background-color: #000;
    color: #FFF !important;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 19px 41px;
    position: relative;
}
@media screen and (max-width: 768px) {
    .af-html {
        padding: 3rem 2rem 2rem 2rem !important;
    }
    .af-html *[style*="font-size:20px"] {
        font-size: 16px !important;
    }
    .af-html span[style^="font-size:4"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-html *[style*="line-height:48px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-html *[style^="line-height:5"] { line-height: 30px !important; }
    .af-html *[style*="font-size:42px"] { font-size: 30px !important; }
    .af-html .container-flex { flex-flow: column; }
    .af-html .container-left, .container-right { margin-bottom: 2rem; }
}


/* ======= AF Footer ======= */
.af-footer {
    background-color: #000;
    width: 100vw;
    padding: 3rem 2rem;
    color: #FFF;
    display: flex;
}
.af-footer .grow-1 { flex-grow: 1; }
.af-footer .grow-2 { flex-grow: 2; }
.af-footer .grow-3 { flex-grow: 3; }
.af-footer .footer-section {
    margin-right: 3rem;
    font-weight: 800;
}
.af-footer .footer-section .footer-section-contact-header {
    font-size: 42px;
    font-weight: 800;
}
.af-footer .footer-section p {
    margin: 1rem 0;
    font-size: 22px;
}
.af-footer .form-post-message {
    font-size: 17px !important;
    margin-top: .5rem !important;
}
.af-footer .footer-svg-logo {
    width: 150px;
}
.af-footer .icons-container-wrapper {
    display: flex;
    flex-flow: column;
    font-weight: 600;
}
.af-footer .icons-container-wrapper label {
    text-align: left;
    line-height: 1.5;
}
.af-footer .icons-container-wrapper .icons-container {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 5px;
    margin-bottom: 15px;
}
.af-footer .icons-container-wrapper .icons-container a {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #FFF;
    background-position: center;
    margin-right: 19px;
}
.af-footer .icons-container-wrapper .icons-container a:hover {
    background-color: #F2F2F2;
}
.af-footer .form-container-wrapper {
    display: flex;
    flex-flow: column;
    text-align: left;
}
.af-footer .form-container-wrapper .form-desc {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.af-footer .form-container-wrapper .form-label {
    position: relative;
    font-size: 20px;
    text-align: left;
    margin-top: 10px;
}
.af-footer .form-container-wrapper .form-input input {
    border: 1px solid #FFF;
    border-radius: 0;
    padding: 8px 20px;
}
.af-footer .form-container-wrapper .form-label:after {
    width: 100%;
    height: 2px;
    background-color: #FFF;
    position: absolute;
    bottom: -.5rem;
    left: 0;
}
.af-footer .autodesk-link {
    font-weight: 400;
    font-size: 12px;
    text-decoration: underline;
}
.af-footer .form-container-wrapper textarea {
    resize: none;
    border-radius: 0;
}
@media screen and (max-width: 768px) {
    .af-footer { flex-flow: column; }
    .af-footer .form-post-message {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .af-footer .footer-section .footer-section-contact-header { font-size: 30px; }
    .af-footer .footer-section p { font-size: 18px; }
    .af-footer .footer-section { margin-bottom: 2rem; margin-right: 0; }
    .af-footer .form-container-wrapper .form-desc { font-size: 18px; }
    .af-footer .grow-3 { margin-top: 2rem; }
}


/* ======= AF Text ======= */
.af-text {
    padding: 2rem;
}
.af-text h2 {
    font-weight: 600;
    margin-bottom: 1rem;
}
.af-text p {
    font-weight: 400;
    max-width: 50%;
}



/* ======= AF Button ======= */
.af-button {}
.af-button .af-buttons-wrapper {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    height: 100%;
}
.af-button .af-buttons-wrapper button {
    height: 60px;
}
@media screen and (max-width: 768px) {
    .af-button .af-buttons-wrapper {
        padding: 2rem;
    }
}


/* ======= AF CTA Background ======= */
.af-cta-background {
    position: relative;
    padding: 2rem;
}
.af-cta-background .text-container {
    z-index: 100;
    max-width: 39%;
    position: relative;
    animation: slide-in-left 2000ms ease forwards;
}
.af-cta-background .buttons-container {
    margin-top: 2rem;
}
.af-cta-background .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.af-cta-background .background-image img {
    object-fit: cover;
    height: 100%;
}
@media screen and (max-width: 400px) {
    .af-cta-background .text-container {
        padding-right: 1rem;
    }
}
@media screen and (max-width: 768px) {
    .af-cta-background .text-container {
        max-width: 80%;
    }
}
@media screen and (min-width: 1550px) {
    .height-70 {
        height: 70vh;
    }

    .height-70 {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}


/* ======= AF Timer ======= */
.af-timer {
    display: flex;
    align-items: flex-start;
    padding: 6rem 2rem;
}
.af-timer .align-top {
    justify-content: flex-start !important;
}
.af-timer .timer-section {
    display: flex;
    flex-flow: column;
    flex-grow: 2;
    flex-basis: 50%;
    justify-content: center;
    margin-right: 3rem;
}
.af-timer .timer-section .gmt {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 1rem;
}
.af-timer .timer-section .label {
    font-size: 42px;
    font-weight: 800;
    text-align: left;
    line-height: 48px;
}
.af-timer .timer-section .localization {
    font-size: 20px;
    text-align: left;
    font-weight: 800;
    margin-bottom: 1rem;
}
.af-timer .timer-section .timer-wrapper {
    font-size: 150px;
    font-weight: 800;
    white-space: nowrap;
}
@media screen and (min-width: 768px) and (max-width: 1450px) {
    .af-timer .timer-section .timer-wrapper {
        font-size: 80px;
    }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
    .af-timer .timer-section .label { font-size: 30px; }
    .af-timer .timer-section .timer-wrapper { font-size: 60px; }
}
@media screen and (max-width: 767px) {
    .af-timer { flex-flow: column; }
    .af-timer .timer-section { flex-basis: unset; }
    .af-timer .timer-section .label { font-size: 30px; line-height: 38px; }
    .af-timer .timer-section .gmt { font-size: 16px; }
    .af-timer .timer-section .localization { font-size: 18px; }
    .af-timer .timer-section .timer-wrapper { font-size: 55px; }
    .af-timer .align-top { margin-bottom: 2rem; }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
    .af-timer .timer-section .timer-wrapper { font-size: 45px; }
}



/* ======= AF Timer ======= */
.af-cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #000;
    color: #FFF;
    display: flex;
    /*flex-flow: row nowrap;*/
    flex-flow: column;
    padding: 40px;
    z-index: 10000;
    transform: translateY(100%);
    animation: show-cookies-banner 800ms 2000ms forwards;
}
.af-cookies-banner .banner-section {
    flex-grow: 2;
    flex-basis: 50%;
    background-color: #000;
    line-height: 22px;
    margin-right: 2rem;
}
.af-cookies-banner .banner-section label { font-size: 16px !important; }
.af-cookies-banner .close-banner {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 1rem;
    height: 1rem;
}
.af-cookies-banner .banner-section .buttons-container {
    display: flex;
}
.af-cookies-banner .banner-section .buttons-container button {
    padding: 10px 25px;
}
.af-cookies-banner .banner-section .buttons-container button:first-child {
    background-color: #FFF;
    color: #000;
}
.af-cookies-banner .banner-section .buttons-container button:first-child:hover {
    color: #000 !important;
    background-color: #FFF !important;
}
.af-cookies-banner .banner-section .buttons-container button:last-child:hover {
    color: #FFF !important;
}
@keyframes show-cookies-banner {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
}
@media screen and (max-width: 768px) {
    .af-cookies-banner { flex-flow: row wrap; padding: 2rem 2rem; }
    .af-cookies-banner .buttons-container { margin-top: 1rem; }
}


/* ======= AF Persons ======= */
.af-persons {
    padding: 2rem;
}
.af-persons img {
    width: auto !important;
    max-width: 100%;
    min-height: 320px;
    margin-bottom: 1.5rem;
}
.af-persons ul {
    margin-left: 0 !important;
    padding-left: 1.3rem !important;
}
.af-persons li {
    line-height: 28px;
}

@media screen and (max-width: 768px) {
    .af-persons br { margin: 0 !important; line-height: 0 !important; display: none; }
    .af-persons img {
        min-height: 220px;
    }
    .af-persons span[style="font-size:35px; line-height:40px"] {
        font-size: 24px !important;
        line-height: 38px !important;
    }
    .af-persons span[style="font-size:20px; line-height:28px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .af-persons ul {
        margin-left: 0 !important;
        padding-left: 1.5rem !important;
    }
    .af-persons li * {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}


/* ======= AF Text Carousel ======== */
.af-text-carousel {
    display: flex;
    padding: 4rem 2rem;
}
.af-text-carousel .animated-content { width: 100%; }
.af-text-carousel .html-content-container {
    line-height: 32px;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-text-carousel .html-content-container *[style*="font-size:42px"] {
    line-height: 55px;
}
.af-text-carousel .carousel-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-text-carousel .carousel-container ul {
    list-style-type: none;
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
    padding-left: 0;
    min-height: 185px;
    position: relative; width: 100%;
}
.af-text-carousel .carousel-container ul li {
    position: absolute;
    font-size: 20px;
    line-height: 28px;
    opacity: 0;
    text-align: center;
    white-space: pre-wrap;
    width: 100%;
    transform: translateY(-100%);
    animation: hide-li 500ms ease forwards;
}
.af-text-carousel .carousel-container ul li[data-active="true"] {
    animation: show-li 500ms ease forwards !important;
}
.af-text-carousel .carousel-container li h1 { font-weight: 600; }
@keyframes hide-li {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateY(-40px);
    }
}
@keyframes show-li {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@media screen and (max-width: 768px) {
    .af-text-carousel {
        flex-flow: column;
        padding: 2.5rem 2rem;
    }
    .af-text-carousel .carousel-container {
        padding-top: 0;
    }
    .af-text-carousel .carousel-container ul {
        padding: 0;
        min-height: 260px;
        transition: height 200ms ease-in-out;
    }
    .af-text-carousel .carousel-container ul li {
        font-size: 16px;
        line-height: 22px;
    }
    .af-text-carousel .carousel-container ul li h1 {
        font-size: 30px;
        line-height: 38px !important;
    }
    .af-text-carousel .html-content-container p { font-size: 16px !important; line-height: 22px; }
    .af-text-carousel .html-content-container p span[style*="font-size:20px"] { font-size: 16px !important; }
    .af-text-carousel .html-content-container p span[style*="line-height:48px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-text-carousel .html-content-container *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-text-carousel .html-content-container *[style*="line-height:48px"] { line-height: 38px !important; }
}


/* ======= AF Video Banner ======= */
.pure-af-video-banner-container {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    position: relative;
    background-color: black;
    height: 60vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}
.pure-af-video-banner-container .content-container h2 {
    white-space: pre-wrap;
    font-weight: 600;
    margin-bottom: 10px;
}
.pure-af-video-banner-container .content-container p {
    white-space: pre-wrap;
    margin-bottom: 40px;
}
.pure-af-video-banner-container .content-container {
    max-width: 600px;
    position: relative;
    z-index: 2;
}
.pure-af-video-banner-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.pure-af-video-banner-container .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
    transition: background-color 600ms ease-in;
}

@media screen and (max-width: 768px) {
    .pure-af-video-banner-container {
        height: 100%;
        padding: 3rem 2rem;
    }
    .pure-af-video-banner-container h2 {
        font-size: 30px;
        line-height: 35px !important;
        margin-bottom: 30px !important;
    }
    .pure-af-video-banner-container p {
        font-size: 16px;
        line-height: 24px !important;
        margin-bottom: 25px !important;
    }
}

/* ======= KMK Image Override ======= */
@media screen and (max-width: 600px) {
    .kmk-image-tile .stack-images-1 img {
        min-height: 300px !important;
    }
}


/* ======= AF Responsive Banner ======= */
.af-responsive-banner img {
    height: 100%;
}

/* ======= AF Image Carousel ======= */
.af-image-carousel .af-image-carousel-swiper {
    display: flex;
    flex-flow: row;
}
.af-image-carousel p {
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 28px;
}
.af-image-carousel .carousel-container {
    padding: 3rem 2rem;
    width: 50%;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-image-carousel .image-container {
    flex-basis: 50%;
    flex-grow: 1;
    max-width: 50%;
    min-height: 500px;
    overflow: hidden;
    position: relative;
}
.af-image-carousel .image-container .image-container-element {
    position: relative;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}
.af-image-carousel .image-container .image-html-container {
    padding: 2rem;
    max-width: 400px;
    position: absolute;
    top: 1rem;
    left: 0;
}
.af-image-carousel .image-container .image-html-container h1 {
    font-weight: 600;
}
.af-image-carousel .images-strip {
    position: relative;
    display: flex;
    flex-flow: row;
    height: 100%;
    transition: transform 600ms ease-in-out;
}
.af-image-carousel .images-strip .image-container-element {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
.af-image-carousel .images-strip img {
    object-fit: cover;
    object-position: left;
    width: 100%;
}
.af-image-carousel .carousel-container ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 42px;
    line-height: 55px;
    font-weight: 600;
    position: relative;
}
.af-image-carousel .carousel-container li { cursor: pointer; }
.af-image-carousel .carousel-container li i + span:before { background-color: red; }
.af-image-carousel .carousel-container li span { font-weight: 600; position: relative; }
.af-image-carousel .carousel-container li span:before {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--underline-color);
    transform-origin: left;
    transition: transform 600ms ease-in-out;
    transform: scaleX(0);
}
.af-image-carousel .carousel-container li[data-active="true"] span:before {
    transform: scaleX(1);
}
@media screen and (max-width: 768px) {
    .af-image-carousel .af-image-carousel-swiper  {
        flex-flow: column;
    }
    .af-image-carousel .image-container {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        min-height: 400px;
        margin-bottom: 2rem;
    }
    .af-image-carousel .image-container .image-html-container {
    }
    .af-image-carousel .image-container .linear-gradient {
        display: none;
    }
    .af-image-carousel .image-container img {
        object-fit: cover;
        max-height: unset !important;
    }
    .af-image-carousel .carousel-container {
        width: 100%;
        flex-basis: 50%;
        max-height: 180px;
        padding: 3rem 2rem 1rem 2rem;
    }
    .af-image-carousel p {
        font-size: 16px;
        line-height: 24px;
    }
    .af-image-carousel .carousel-container ul {
        margin: 20px 0;
    }
    .af-image-carousel .carousel-container li {
        font-size: 24px;
        line-height: 32px;
    }
    .af-image-carousel .carousel-container li span:before { content: none; }
    .af-image-carousel .carousel-container li[data-active="false"] {
        display: none;
    }
    .af-image-carousel .images-strip .image-container-element h1 { font-size: 30px !important; line-height: 38px !important; }
    .af-image-carousel .images-strip .image-container-element *[style*="border-bottom:1px solid"] { margin-bottom: 1rem; }
}

/* ============= AF MAP ============== */
.af-map { position: relative; }
.af-map .af-map-label {
    position: absolute;
    top: 72px;
    left: 37px;
    z-index: 100;
    color: #FFF;
}
@media screen and (max-width: 768px) {
    .af-map .af-map-label {
        top: 2rem;
        left: 2rem;
    }
    .af-map .af-map-label *[style*="font-size:48px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-map .af-map-label *[style*="font-size:18px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

/* ============= AF Column Html ============== */
.af-column-html {
    padding: 4.5rem 2rem;
    display: flex;
    flex-flow: row nowrap;
}
.af-column-html div { flex-grow: 2; flex-basis: 50%; }
@media screen and (max-width: 768px) {
    .af-column-html {
        flex-flow: column;
        padding: 3.5rem 2rem;
    }
    .af-column-html div:last-child {
        padding-bottom: 1rem;
    }
    .af-column-html *[style*="font-size:42px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-column-html *[style*="font-size:20px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

/* ============= AF Image Transition ============== */
.af-image-transition {}
.af-image-transition div:first-child {
    position: relative;;
    min-height: 700px;
}
.af-image-transition img:first-child { opacity: 1; }
.af-image-transition img {
    opacity: 0;
    position: absolute;
    height: 100%;
    object-fit: contain;
    transition: opacity 600ms ease;
}
@media screen and (max-width: 768px) {
    .af-image-transition div:first-child { min-height: 200px !important; }
    .af-image-transition img { object-fit: cover !important; }
    .af-image-transition .overlay-logo {
        max-width: 45px !important;
        max-height: 45px;
        height: 45px;
        bottom: 1rem;
        right: 1rem;
    }
    .af-image-transition .overlay-logo img {
        width: 45px !important;
        min-height: 45px !important;
        max-height: 45px !important;
    }
}


/* ============= AF Slider Inidicator ============ */
.slider-indicator {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}
.slider-indicator .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}
.slider-indicator .dot i {
    display: block;
    border-radius: 50%;
    background-color: #474747;
    width: 8px;
    height: 8px;
    max-width: 8px;
    transition: background-color 800ms ease-in-out;
}
.slider-indicator .dot[data-active="true"] i {
    background-color: #FFF;
}
@media screen and (min-width: 768px) {
    .slider-indicator { display: none; }
}


/* ============= AF Booklet ============ */
.af-booklet {}
.af-booklet .af-booklet-swiper {
    display: flex;
    flex-flow: row nowrap;
}
.af-booklet .af-booklet-swiper .text-container {
    padding: 4rem 2rem 2rem 2rem;
    flex-basis: 50%;
    color: #FFF;
    min-height: 550px;
}
.af-booklet .af-booklet-swiper .text-container .slider-indicator {
    position: absolute;
    transform: none;
    bottom: 2rem;
    left: unset;
    margin-top: 25px;
    display: flex;
}
.af-booklet .af-booklet-swiper .image-container {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 2;
    overflow: hidden;
}
.af-booklet .af-booklet-swiper .text-container {
    position: relative;
}
.af-booklet .af-booklet-swiper .text-container .text-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4rem 2rem;
}
.af-booklet .af-booklet-swiper .text-container label { display: block; font-weight: 600; margin-bottom: 25px; }
.af-booklet .af-booklet-swiper .text-container h2 {
    font-weight: 600;
    font-size: 32px !important;
    line-height: 48px !important;
    max-width: 80%;
    margin-bottom: 40px;
    padding-right: 4rem;
}
.af-booklet .af-booklet-swiper .text-container p {
    padding-right: 4rem;
    max-width: 80%;
}
.af-booklet .af-booklet-swiper .text-container h2 span { position: relative }
.af-booklet .af-booklet-swiper .image-container {
    position: relative;
}
.af-booklet .af-booklet-swiper .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 500ms ease-in-out;
}
@media screen and (max-width: 768px) {
    .af-booklet .af-booklet-swiper {
        flex-flow: column;
    }
    .af-booklet .af-booklet-swiper .image-container {
        height: 300px;
        width: 100%;
        min-height: 300px;
    }
    .af-booklet .af-booklet-swiper .text-card h2 {
        max-width: 100%;
        padding-right: 0;
    }
}


/* ============= AF Video ============ */
.af-video .af-video-wrapper {
    display: flex;
    flex-flow: row;
}
.af-video .wrapper-align-right {
    flex-flow: row-reverse;
}
.af-video .video-container { 
    flex-basis: 50%; 
    width: 50%;
    overflow: hidden;
}
.af-video .video-container video { width: 100%; height: 100%; min-height: 550px; object-fit: cover; }
.af-video .text-container { flex-basis: 50%; width: 50%; padding: 4rem 2rem; }
.af-video .text-container *[style*="font-size:42px"] { line-height: 48px; }
.af-video .text-container *[style*="font-size:20px"] { line-height: 28px; }
@media screen and (max-width: 768px) {
    .af-video .af-video-wrapper { flex-flow: column; }
    .af-video .wrapper-align-right { flex-flow: column-reverse; }
    .af-video .text-container, .af-video .video-container {
        width: 100%;
        flex-basis: 100%;
    }
    .af-video .text-container *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-video .text-container *[style*="font-size:20px"] { font-size: 16px !important; line-height: 22px !important; }
}
