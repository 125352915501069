.loader-container {
    margin-top: 4rem;
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader{
    width: 50px;
    height: 50px;
    animation: heartbeat 1s infinite;
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}