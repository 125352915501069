@import './index_reset.css';
/*
  Document breakpoints:
  - desktop > 1200px
  - medium 768px - 1200px
  - mobile 320px - 768px
*/

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 { font-size: 42px; font-weight: 300; line-height: 55px !important; } /* 42px */
h2 { font-size: 42px; font-weight: 300; line-height: 55px !important; } /* 42px */
h3 { font-size: 35px; line-height: 40px !important; }
h4 { font-size: 20px; line-height: 28px !important; }
p { font-size: 20px; line-height: 28px; }
label { font-size: 20px; line-height: 28px; }

@media screen and (max-width: 768px) {
  html { font-size: 14px; }
  body { font-size: 14px; }
  h1 { font-size: 30px; line-height: 35px !important; }
  h2 { font-size: 30px; line-height: 35px !important; }
  h3 { font-size: 16px; line-height: 22px !important; }
  h4 { font-size: 16px; line-height: 22px !important; }
  p { font-size: 16px; line-height: 22px !important; }
}

/* Fonts */
h1 { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
h2 { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
p { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
button { 
  font-family: inherit !important;
  background-color: #000;
  color: #FFF;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  padding: 14px 29px;
  position: relative;
  white-space: nowrap;
  border: 1px solid transparent;
  transition: all 400ms ease-in-out;
}
button::-moz-focus-inner { border: 0; }
button:hover {
  background-color: #000 !important;
  color: #FFF;
}
button[data-no-change]:hover {
  opacity: 1 !important;
  border: transparent 1px solid;
  color: #FFF !important;
  background-color: #000 !important;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  button:hover {
    color: #FFF !important;
    text-decoration: underline;
  }
}

.react-ripples {
  border-radius: 30px;
}

a {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  position: relative; 
  cursor: pointer; 
  z-index: 100;
}

.styled-link {
  text-decoration: underline;
  font-weight: 400;
}
.styled-link:hover {
  font-weight: 600;
}
.underline {
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 4px), var(--underline-color) 4px);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 1s;
}
.underline[data-active] {
  background-size: 100% 100%;
}

@media screen and (max-width: 768px) {
  .link:hover:before {
    height: 2px;
  }
  a:hover:before {
    height: 2px;
  }
}

/** Layout **/
.masonry-layout {
  display: flex;
  flex-flow: row wrap;
  background: #FFF;
  overflow: hidden;
  margin: auto;
}

.text-underline { position: relative; }

.text-underline:before {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E53935;
}

.col-6 { flex-basis: 100%; width: 100%; }
.col-5 { flex-basis: 83%; width: 83%; }
.col-4 { flex-basis: 66%; width: 66%; }
.col-3 { flex-basis: 50%; width: 50%; }
.col-2 { flex-basis: 33.3%; width: 33.3%; }
.col-1 { flex-basis: 16.6%; width: 16.6%; }

.offset-0 { margin-left: 0; }
.offset-1 { margin-left: 16.6%; }
.offset-2 { margin-left: 25%; }
.offset-3 { margin-left: 50%; }
.offset-4 { margin-left: 66%; }

@media screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%;
    flex-basis: 100%;
  }
  [class*="offset-"] {
    margin-left: 0;
  }
}

.lock-scroll {
  overflow: hidden;
}

.no-margin {
  margin: 0 !important;
}

.app-button {
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1rem;
  color: #FFF;
  cursor: pointer;
}

.lazy-image {
  transition: filter 1300ms ease;
}

.lazy-image[data-loading="true"] {
  filter: blur(8px);
}
.lazy-image[data-loading="false"] {
  filter: blur(0px);
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
  display: inherit !important;
  justify-content: inherit !important;
}

.top-bar-search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 80px;
    top: 20px;
    cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  animation: fade-in 500ms ease forwards;
}

@media screen and (max-width: 768px) {
  .top-bar-search-icon {
    right: 65px;
    top: 17px;
  }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
  .blog-layout .col-3 {
    width: 50%;
    flex-basis: 50%;
  }
}
