.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 7rem;
    z-index: 1000;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 2rem 2rem;
}
.top-bar .top-bar-logo {
    width: 200px;
    height: 80px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 400ms ease-in-out;
}
.top-bar-info-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #FFF;
    font-size: 14px;
    transition: all 300ms ease-in-out;
}
.top-bar-info-container button { transition: all 300ms ease-in-out; }
.top-bar-info-container span {
    margin: 0 1rem;
    font-weight: 600;
}
.top-bar-info-container i {
    display: block;
    width: 40px;
    height: 1px;
    margin: 0 1rem;
    background-color: #FFF;
}
.top-bar-info-container a:hover {
    text-decoration: underline;
}
.top-bar-info-container a[data-active="true"] {
    text-decoration: underline !important;
}
.top-bar-info-container .last-el {
    margin-right: 1rem;
}
.svg-logo-group {
    transition: fill 500ms ease-in-out;
}
@media screen and (max-width: 1100px) {
    .top-bar-info-container {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .top-bar {
        padding: 0 2rem;
        height: 5rem;
    }
    .top-bar .top-bar-logo {
        width: 120px;
        height: 60px;
    }
}
