html,
body,
nav,
input,
div {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1;
    font-weight: 400;
    box-sizing: border-box;
}
*:after, *:before {
    content: '';
    position: absolute;
    box-sizing: inherit;
    text-decoration: inherit;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input {
    border: none;
    outline: none;
    box-sizing: border-box;
}
button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}
a {
    outline: none;
    text-decoration: none;
    border: none;
    color: inherit;
}
a:link, a:visited, a:hover, a:active{
    color: inherit;
}
html {
    min-width: 320px;
}
body { 
    background: #FFF;
    margin: auto;
}
p {
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4{
    /*color: #000;*/
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    min-width: 12rem;
    display: block;
}

/*
  Document breakpoints:
  - desktop > 1200px
  - medium 768px - 1200px
  - mobile 320px - 768px
*/

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 { font-size: 42px; font-weight: 300; line-height: 55px !important; } /* 42px */
h2 { font-size: 42px; font-weight: 300; line-height: 55px !important; } /* 42px */
h3 { font-size: 35px; line-height: 40px !important; }
h4 { font-size: 20px; line-height: 28px !important; }
p { font-size: 20px; line-height: 28px; }
label { font-size: 20px; line-height: 28px; }

@media screen and (max-width: 768px) {
  html { font-size: 14px; }
  body { font-size: 14px; }
  h1 { font-size: 30px; line-height: 35px !important; }
  h2 { font-size: 30px; line-height: 35px !important; }
  h3 { font-size: 16px; line-height: 22px !important; }
  h4 { font-size: 16px; line-height: 22px !important; }
  p { font-size: 16px; line-height: 22px !important; }
}

/* Fonts */
h1 { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
h2 { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
p { font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important; }
button { 
  font-family: inherit !important;
  background-color: #000;
  color: #FFF;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 0;
  padding: 14px 29px;
  position: relative;
  white-space: nowrap;
  border: 1px solid transparent;
  transition: all 400ms ease-in-out;
}
button::-moz-focus-inner { border: 0; }
button:hover {
  background-color: #000 !important;
  color: #FFF;
}
button[data-no-change]:hover {
  opacity: 1 !important;
  border: transparent 1px solid;
  color: #FFF !important;
  background-color: #000 !important;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  button:hover {
    color: #FFF !important;
    text-decoration: underline;
  }
}

.react-ripples {
  border-radius: 30px;
}

a {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  position: relative; 
  cursor: pointer; 
  z-index: 100;
}

.styled-link {
  text-decoration: underline;
  font-weight: 400;
}
.styled-link:hover {
  font-weight: 600;
}
.underline {
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 4px), var(--underline-color) 4px);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 1s;
}
.underline[data-active] {
  background-size: 100% 100%;
}

@media screen and (max-width: 768px) {
  .link:hover:before {
    height: 2px;
  }
  a:hover:before {
    height: 2px;
  }
}

/** Layout **/
.masonry-layout {
  display: flex;
  flex-flow: row wrap;
  background: #FFF;
  overflow: hidden;
  margin: auto;
}

.text-underline { position: relative; }

.text-underline:before {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E53935;
}

.col-6 { flex-basis: 100%; width: 100%; }
.col-5 { flex-basis: 83%; width: 83%; }
.col-4 { flex-basis: 66%; width: 66%; }
.col-3 { flex-basis: 50%; width: 50%; }
.col-2 { flex-basis: 33.3%; width: 33.3%; }
.col-1 { flex-basis: 16.6%; width: 16.6%; }

.offset-0 { margin-left: 0; }
.offset-1 { margin-left: 16.6%; }
.offset-2 { margin-left: 25%; }
.offset-3 { margin-left: 50%; }
.offset-4 { margin-left: 66%; }

@media screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%;
    flex-basis: 100%;
  }
  [class*="offset-"] {
    margin-left: 0;
  }
}

.lock-scroll {
  overflow: hidden;
}

.no-margin {
  margin: 0 !important;
}

.app-button {
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1rem;
  color: #FFF;
  cursor: pointer;
}

.lazy-image {
  transition: -webkit-filter 1300ms ease;
  transition: filter 1300ms ease;
  transition: filter 1300ms ease, -webkit-filter 1300ms ease;
}

.lazy-image[data-loading="true"] {
  -webkit-filter: blur(8px);
          filter: blur(8px);
}
.lazy-image[data-loading="false"] {
  -webkit-filter: blur(0px);
          filter: blur(0px);
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
  display: inherit !important;
  justify-content: inherit !important;
}

.top-bar-search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 80px;
    top: 20px;
    cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  -webkit-animation: fade-in 500ms ease forwards;
          animation: fade-in 500ms ease forwards;
}

@media screen and (max-width: 768px) {
  .top-bar-search-icon {
    right: 65px;
    top: 17px;
  }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
  .blog-layout .col-3 {
    width: 50%;
    flex-basis: 50%;
  }
}

.menu-toggle {
    position: fixed;
    /* right: 1.5rem; */
    left: calc(100vw - 64px);
    top: .6em;
    z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
@media screen and (min-width: 1025px) {
    .menu-toggle {
        display: none;
    }
}
.menu-toggle .hamburger {
      position:absolute;
      height:100%;
      width:100%;
}
.menu-toggle .hamburger span {
    width: 100%;
    height: 2px;
    position:relative;
    margin: 8px 0;
    display:block;
    background:#FFF;
    transition: width 300ms ease;
}
.menu-toggle .hamburger span:nth-child(1) {
    transition-delay:.5s;
}
.menu-toggle .hamburger span:nth-child(2) {
    transition-delay:.625s;
}
.menu-toggle .hamburger span:nth-child(3) {
    transition-delay:.75s;
}
.menu-toggle .cross {
    position:absolute;
    height:100%;
    width:100%;
    -webkit-transform:rotate(45deg);
            transform:rotate(45deg);
}
.menu-toggle .cross span {
    background:#000;
    transition: all 300ms ease;
}
.menu-toggle .cross span:nth-child(1) {
    height: 0%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 14px;
    transition-delay:0s;
}
.menu-toggle .cross span:nth-child(2) {
    width: 0%;
    height: 2px;
    position:absolute;
    left: 0;
    top: 14px;
    transition-delay:.25s;
}

.menu-toggle.open .hamburger span {
    width:0%;
}
.menu-toggle.open .hamburger span:nth-child(1) {
    transition-delay:0s;
}
.menu-toggle.open .hamburger span:nth-child(2) {
    transition-delay:.125s;
}
.menu-toggle.open .hamburger span:nth-child(3) {
    transition-delay:.25s;
}
.menu-toggle.open .cross span:nth-child(1) {
    height: 100%;
    background-color: #FFF;
    transition-delay:.625s;
}
.menu-toggle.open .cross span:nth-child(2) {
    width: 100%;
    background-color: #FFF;
    transition-delay:.375s;
}
.menu-toggle.inverted .hamburger span {
    background: #000;
}

@media screen and (max-width: 768px) {
    .menu-toggle {
        left: calc(100vw - 50px);
    }
}

.kmk-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    padding: 10rem 2rem;
    background: #FFF;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    background-color: #000;
    color: #FFF;
    -webkit-overflow-scrolling: touch;
    transition: opacity 500ms ease, -webkit-transform 400ms ease;
    transition: transform 400ms ease, opacity 500ms ease;
    transition: transform 400ms ease, opacity 500ms ease, -webkit-transform 400ms ease;
    transition-delay: 300ms;
}
/* Menu is active */
.kmk-menu-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.kmk-menu-active .kmk-menu-section section {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    transition: all 1000ms ease 600ms;
}
.kmk-menu-active .kmk-menu-logo img {
    opacity: 1;
    transition: opacity 600ms ease 500ms;
}
.kmk-menu-logo {
    position: absolute;
    top: 2rem;
    left: 4rem;
}
.kmk-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: opacity 300ms ease;
    -webkit-animation: fade-in 400ms ease forwards;
            animation: fade-in 400ms ease forwards;
}
.kmk-menu-overlay-active {
    opacity: 1;
    z-index: 9998;
}
.kmk-menu .menu-logo {
    width: 120px;
    position: absolute;
    top: 0rem;
    left: 2rem;
}
.kmk-menu .menu-icon {
    width: 33px;
    max-width: 33px;
    height: 33px;
    padding: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.kmk-menu .menu-inner-container {
    display: flex;
    flex-flow: column;
}
.kmk-menu .menu-inner-container div:first-child { margin-bottom: 80px; }
.kmk-menu .menu-inner-container div:nth-child(2) { margin-bottom: 40px; }
.links-container {
    display: flex;
    flex-flow: column;
    font-weight: 400;
    font-size: 18px;
    color: #FFF;
    line-height: 2;
}
.kmk-menu .contact-container label {
    font-weight: 800;
    font-size: 24px;
    margin: 1rem 0;
    display: block;
}
.kmk-menu .contact-container div {
    font-weight: 600;
    line-height: 1.5;
}
.kmk-menu .links-container a[data-active="true"] {
    text-decoration: underline;
}

@-webkit-keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 7rem;
    z-index: 1000;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 2rem 2rem;
}
.top-bar .top-bar-logo {
    width: 200px;
    height: 80px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 400ms ease-in-out;
}
.top-bar-info-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #FFF;
    font-size: 14px;
    transition: all 300ms ease-in-out;
}
.top-bar-info-container button { transition: all 300ms ease-in-out; }
.top-bar-info-container span {
    margin: 0 1rem;
    font-weight: 600;
}
.top-bar-info-container i {
    display: block;
    width: 40px;
    height: 1px;
    margin: 0 1rem;
    background-color: #FFF;
}
.top-bar-info-container a:hover {
    text-decoration: underline;
}
.top-bar-info-container a[data-active="true"] {
    text-decoration: underline !important;
}
.top-bar-info-container .last-el {
    margin-right: 1rem;
}
.svg-logo-group {
    transition: fill 500ms ease-in-out;
}
@media screen and (max-width: 1100px) {
    .top-bar-info-container {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .top-bar {
        padding: 0 2rem;
        height: 5rem;
    }
    .top-bar .top-bar-logo {
        width: 120px;
        height: 60px;
    }
}

/*
    Document breakpoints:
    - desktop > 1200px
    - medium 768px - 1200px
    - mobile 320px - 768px
*/

/* ================================= KMK TITLE TILE ================================= */
.kmk-title-tile {
    width: 100vw;
    height: 80vh;
    position: relative;
}

.kmk-title-tile .kmk-title-tile-container {
    overflow: hidden;
}

.kmk-title-tile-background {
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kmk-title-tile img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    will-change: transform;
}

.kmk-title-tile h1 {
    position: absolute;
    white-space: pre-wrap;
    bottom: 2rem;
    left: 2rem;
    font-family: 'Eksell', sans-serif;
}

.kmk-logo {
    position: fixed;
    width: 50px !important;
    height: 50px !important;
    top: 1rem;
    right: 1rem;
    z-index: 10000;
}

@media screen and (max-width: 768px) {
    .kmk-title-tile {
        height: 50vh;
        /*margin-top: 4rem;*/
    }
    .kmk-title-tile h1 {
        font-size: 24px;
        right: 2rem;
        bottom: unset;
        top: 2rem;
    }
    .kmk-title-tile img {
        height: 50vh;
    }
}

/* ================================= KMK TEXT TILE ================================= */

.kmk-text-tile {
    padding: 2rem;
    line-height: 1.5;
    display: flex;
    flex-flow: column;
    background-size: cover !important;
    background-position: center !important; 
    background-repeat: no-repeat !important;
}

.kmk-text-tile .with-minimal-height {
    min-height: 400px;
}

.kmk-text-tile h1 {
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}
.kmk-text-tile .no-margin h1 {
    margin-bottom: 0;
}

.kmk-text-tile h2 {
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

.kmk-text-tile p {
    line-height: 26px;
    font-size: 1rem;
    color: #171717;
    padding-bottom: 1rem;
    white-space: pre-wrap;
}

.p-offset {
    margin-top: 7.2rem;
}

@media screen and (max-width: 768px) {
    .kmk-text-tile { 
        padding-bottom: 2rem !important; 
        padding-top: 2rem !important; 
    }
    .kmk-text-tile p {
        padding-top: 1rem !important;
        line-height: 1.6;
    }
    .kmk-text-tile h1, p {
        padding: 0 !important;
    }
    .p-offset { margin-top: 0; }
}

/* ================================= KMK IMAGE TILE ================================= */

.kmk-image-tile {
    display: flex;
    flex-flow: row nowrap;
}

.kmk-image-tile div { 
    justify-content: inherit; 
    display: inherit; 
    width: 100%; 
}

.kmk-image-tile img { 
    height: auto; 
    object-fit: cover; 
    max-width: 100%;
    /* min-width: 100%; */
    height: 100%;
    box-sizing: border-box;
}
.stack-images-1, .stack-images-2, .stack-images-3 {
    justify-content: center !important;
}/*
.stack-images-1 {
    padding-bottom: .5rem;
}*/
.stack-images-1 img {
    /*  min-width: 100%; */
}
.stack-images-2 {
    overflow: hidden;
}
.stack-images-2 img {
    width: 50% !important;
}
.stack-images-2 img:nth-child(odd) {
    padding-right: .5rem;
}

.kmk-image-tile img[data-visible] { opacity: 1; }

@media screen and (max-width: 600px) {
    .kmk-image-tile div {
        flex-flow: column;
    }
    .kmk-image-tile {
        flex-flow: column;
        align-items: center;
    }
    .kmk-image-tile img {
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
    }
    .stack-images-1 {
        padding-right: 0;
    }
    .stack-images-2 img {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 600px) and (max-width: 769px) {
    .kmk-image-tile {
        flex-flow: row wrap;
        justify-content: center;
        height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .kmk-image-tile img {
        object-fit: cover !important;
        width: 100% !important;
    }
    .kmk-image-tile img[data-nomargin] { 
        margin: 0 !important;
    }
    .stack-images-1 {
        padding-right: 0;
    }
    .stack-images-1 img {
        padding: 0 !important;
    }
    .stack-images-2 {
        flex-flow: column !important;
        align-items: center;
        justify-content: unset !important;
        margin-bottom: 3rem;
    }
    .stack-images-2 img {
        margin: 0 !important;
        padding: 0 0 1rem 0 !important;
        object-fit: contain !important;
    }
    .stack-images-3 {
        flex-flow: column !important;
        align-items: center;
    }
    .stack-images-3 img:not([data-dontscale]) {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        object-fit: contain;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .kmk-image-tile {
        flex-flow: row wrap;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .kmk-image-tile img { 
        width: 100%;
        max-width: 100%;
        flex-grow: 2;
    }
    .stack-images-2 img {
        width: 100% !important;
        margin-bottom: .5rem;
    }
    .stack-images-3 {
        flex-flow: row nowrap !important;
    }
    .stack-images-3 img:not([data-dontscale]) {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        object-fit: cover;
    }
    .kmk-image-tile img[data-nomargin] { 
        margin: 0 !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
    .stack-images-2 {
        flex-flow: column;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) { 
    .stack-images-2 {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: unset;
    }
    .stack-images-2 img {
        margin: 0 !important;
        padding: 0 !important;
        width: 50% !important;
        padding: 0 .5rem !important;
        object-fit: cover !important;
    }
}

@media screen and (min-width: 1800px) {
    .kmk-image-tile img { 
        height: auto; 
        /* width: 100% !important; */
    }
}

.kmk-image-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: #FFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 2000ms ease;
}

.kmk-image-preview-delay { 
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
}

/* ================================= KMK LIST TILE ================================= */
.kmk-list-tile {
    display: flex;
    flex-flow: column;
    padding: 3rem 2rem 2rem 2rem;
}
.kmk-list-tile h1 {
    margin: 0 0 2rem 0;
    font-family: 'Eksell', sans-serif;
}
.kmk-list-tile h2 {
    line-height: 40px !important;
    margin-bottom: 1rem;
}
.kmk-list-tile p {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}
.kmk-list-tile ul {
    list-style-type: disc !important;
    padding-left: 1.5rem;
}
.kmk-list-tile ul li {
    margin-bottom: .5rem;
    font-size: 20px;
    line-height: 28px;
}
.kmk-list-tile label {
    margin-bottom: 2rem;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .kmk-list-tile h2 {
        font-size: 24px !important;
        line-height: 38px !important;
    }
    .kmk-list-tile ul li {
        font-size: 16px;
        line-height: 22px;
    }
}
@media screen and (max-width: 768px){
    .kmk-list-tile {
        padding: 2rem 2rem 0 2rem;
    }
}

/* ================================= KMK MEDIA LINK TILE ================================= */
.kmk-media-tile {
    padding: 2rem;
    line-height: 1.5;
}

.kmk-media-tile img {  width: 170px; height: 170px; max-width: 170px; border-radius: 10px; object-fit: cover; }

.kmk-media-tile a { display: inline-block; margin: 2rem 0; }

.kmk-media-tile p {
    font-size: 1rem;
    padding-bottom: 1rem;
}

/* ================================= KMK IMAGE TEXT TILE ================================ */
.kmk-image-text-tile {
    padding: 2rem;
}

@media screen and (max-width: 768px) {
    .kmk-image-text-tile {
        display: flex;
        flex-flow: column;
    }
    .kmk-image-text-tile-content p {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .kmk-image-text-tile-images img {
        width: 100%;
        padding-right: 2rem;
    }
    .image-align-right {
        flex-flow: column-reverse !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 2rem !important;
    }
    .image-align-left { flex-flow: column-reverse !important; }
    .image-align-left .kmk-image-text-tile-content { margin-bottom: 2rem; }
    .image-align-right .kmk-image-text-tile-content { margin-bottom: 2rem; padding: 0 !important; }
}

.kmk-image-text-tile-images {
    flex-grow: 2;
    flex-basis: 50%;
}
.kmk-image-text-tile-content {
    flex-grow: 1;
    flex-basis: 50%;
    padding-right: 2rem;
}

.kmk-image-text-tile-images img {
    width: 100%;
    height: auto; 
    object-fit: cover;
}

.kmk-image-text-tile-content h1 {
    margin-bottom: 4rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

.kmk-image-text-tile-content p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
}

.image-align-left {
    display: flex;
    flex-flow: row;
}

.image-align-right {
    display: flex;
    flex-flow: row-reverse;
}

.image-align-bottom {
    display: flex;
    flex-flow: column-reverse;
}

.image-align-left img { height: 100%; }

.image-align-right img { height: 100%; }

.image-align-left .kmk-image-text-tile-content { padding-left: 2rem; padding-right: 2rem; }
.image-align-right  { padding-left: 2rem; }

.image-align-bottom { padding-right: 2rem; }
.image-align-bottom img { height: 100%; }
.image-align-bottom .kmk-image-text-tile-content { margin-bottom: 2rem; padding-right: 2rem; }

/* ================================= KMK LINK EMBEDED TILE ================================ */
.kmk-link-embeded-tile {
    padding: 2rem;
}
.kmk-link-embeded-tile > * {
    line-height: 26px;
}
.kmk-link-embeded-tile * {
    line-height: 26px;
}
.kmk-link-embeded-tile h2 {
    padding-bottom: 1rem;
}
.kmk-link-embeded-tile h2 {
    padding-bottom: 1rem;
}
.kmk-link-embeded-tile blockquote {
    line-height: 33px;
    font-size: 1.5rem;
}
.kmk-link-embeded-tile a {
    text-decoration: underline;
}
.kmk-link-embeded-tile a:hover {
    font-weight: 800;
}
.kmk-link-embeded-tile p {
    line-height: 26px;
}
.kmk-link-embeded-tile pre {
    background-color: #292929;
    color: #fff;
    font-size: 13px;
    line-height: 1.3;
    padding: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.kmk-link-embeded-tile ul li {
    line-height: 1.5;
}
.kmk-link-embeded-tile ol li {
    line-height: 1.5;
}

@media screen and (max-width: 768px) {
    .kmk-link-embeded-tile {
        padding-right: 2rem;
    }
    .kmk-link-embeded-tile p {
        line-height: 1.6;
    }
}

/* ================================= KMK PEOPLE TILE ================================ */
.kmk-people-tile {
    display: flex;
    flex-flow: column;
    padding: 1rem 4rem;
}

.kmk-people-tile img {
    height: auto;
    width: 100% !important;
    object-fit: cover;
    page-break-after: always;
    -webkit-column-break-after: always;
            break-after: always;
}

.kmk-people-tile h2 {
    padding: 1rem 0;
    font-size: 24px;
    page-break-after: always;
    -webkit-column-break-after: always;
            break-after: always;
    font-family: 'Eksell', sans-serif;
}

.kmk-people-tile p {
    padding: 1rem 0;
    padding-top: 0;
    font-weight: 300;
    color: #171717;
    line-height: 1.5;
    white-space: pre-wrap;
}

/* ================================= KMK CONTACT TILE ================================ */
.kmk-contact-tile {
    padding: 2rem;
}

.kmk-contact-tile h1 {
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    font-size: 24px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 17rem;
}

.kmk-contact-tile h2 {
    margin: 1rem 0;
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    font-size: 24px;
}

.kmk-contact-tile p {
    line-height: 26px;
    color: #171717;
    white-space: pre-wrap;
}

.kmk-contact-tile img {
    object-fit: contain;
    object-position: left;
    max-height: 400px;
}

@media screen and (min-width: 250px) and (max-width: 420px) {
    .kmk-contact-tile {
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        padding: 2rem;
        padding-left: 4rem;
    }
    .kmk-contact-tile img {
        object-fit: contain;
        object-position: left;
        height: 290px !important;
    }
}

@media screen and (min-width: 420px) and (max-width: 768px) {
    .kmk-contact-tile {
        width: 50% !important;
        max-width: 50% !important;
        flex-basis: 50% !important;
        padding: 2rem;
        padding-left: 4rem; 
    }
    .kmk-contact-tile img {
        object-fit: contain;
        object-position: left;
        object-position: center;
        height: 180px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 908px) {
    .kmk-contact-tile img {
        max-height: 200px;
        object-fit: contain;
        object-position: center;
    }
}

@media screen and (min-width: 908px) and (max-width: 2000px) {
    .kmk-contact-tile {
        padding: 2rem 4rem;
        max-width: 33% !important;
        flex-basis: 33% !important; 
    }
    .kmk-contact-tile img {
        max-height: 250px;
    }
}


/* ================================= KMK CAROUSEL TILE ================================ */
.kmk-carousel-tile {
    width: 100%;
    height: 95vh;
    min-height: 95vh;
    color: #333;
    background: #FFF;
}

.kmk-carousel-image {
    height: 95vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kmk-carousel-html {
    padding: 40px;
    padding-left: 4rem;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.kmk-carousel-html p {
    font-family: 'Eksell', sans-serif;
    font-size: 3rem;
}

.kmk-carousel-html a {
    font-size: 1.25rem;
}

.kmk-carousel-tile img {
    width: 100%;
    height: 100%;
}

.kmk-carousel-image-link {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
}

.kmk-carousel-image-link h1 {
    font-size: 1.2rem;
    padding: 1rem 0;
    margin-left: 1rem;
    font-family: 'Eksell', sans-serif;
}

.kmk-carousel-image-link a {
    margin-left: 1rem;
    font-size: .8rem;
}

.kmk-carousel-image-link-background {
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* ================================= KMK FOOTER TILE ================================ */
.kmk-footer-tile {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.kmk-footer-tile-caption {
    width: 100%;
    height: 54px;
    color: #FFF;
    font-weight: 600;
    background-color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left: 63px;
}
.kmk-footer-tile-container {
    background: #FFF;
    color: #000000;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}
.kmk-footer-tile .icons-container {
    padding-top: 1rem;
    display: flex;
}
.kmk-footer-tile .icons-container img {
    width: 35px;
    height: 35px;
    margin-right: 17px;
    cursor: pointer;
}
.kmk-footer-tile > section > * {
    font-weight: 700;
}
.kmk-footer-tile-section {
    padding: 2rem;
    padding-left: 4rem;
    flex-grow: 1;
    flex-basis: 25%;
}
.kmk-footer-tile-section > div > a {
    margin-bottom: 10px;
    line-height: 1.35;
    font-weight: 600;
    display: block;
}
.kmk-footer-tile-section .section-part {
    display: flex;
    flex-flow: column;
    margin-bottom: 2rem;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper {
    position: relative;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper button {
    position: absolute;
    top: 20px;
    right: 5px;
    width: 35px;
    height: 35px;
    padding: unset !important;
    border-radius: 50%;
    transition: all 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper .submitted {
    background: #4B8700;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper .submitted img {
    position: absolute;
    top: 11px;
    right: 9px;
    width: 15px;
    height: 15px;
}
.kmk-footer-tile-newsletter .newsletter-error {
    margin-bottom: 5px;
}
.kmk-footer-tile-newsletter .newsletter-label {
    white-space: nowrap;
    display: block;
    text-align: left;
}
.kmk-footer-tile-newsletter .newsletter-input {
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #000;
    background-color: #FFF;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 15rem;
    width: 100%;
    color: #000;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input::-webkit-input-placeholder {
    color: #000 !important;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input::-ms-input-placeholder {
    color: #000 !important;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input::placeholder {
    color: #000 !important;
}
.kmk-footer-tile-newsletter .newsletter-input-wrapper input:-moz-placeholder {
    color: #000 !important;
}
.kmk-footer-tile .kmk-checkbox > * {
    font-weight: 400;
}
.kmk-footer-tile-policy-link {
    padding-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .kmk-footer-tile-container {
        flex-flow: row wrap;
    }
    .kmk-footer-tile-section {
        width: 38%;
        flex-basis: 38%;
    }
}

@media screen and (max-width: 768px) {
    .kmk-footer-tile-section { padding-left: 2rem; }
    .kmk-footer-tile-section .section-part label { font-size: 1.2rem; }
    .kmk-footer-tile-container { flex-flow: column; }
    .kmk-footer-tile .kmk-footer-tile-newsletter .newsletter-label {
        text-align: left;
    }
    .kmk-footer-tile-caption {
        padding: 0;
        justify-content: center;
        font-size: 12px;
    }
}

/* ================================= KMK CTA TILE ================================ */
.kmk-cta-tile {
    display: flex;
    padding: 4rem 4rem;
    background: #F6F6F6;
}

.kmk-cta-tile .cta-section {
    flex-basis: 50%;
    flex-grow: 2;
    width: 50%;
    padding-right: 1rem;
}

.kmk-cta-tile .cta-section h2 { 
    font-family: 'Eksell', sans-serif;
    font-weight: 600;
    white-space: pre-wrap;
}

.kmk-cta-tile .cta-section p {
    font-weight: 300;
    color: #171717;
    line-height: 1.5;
    padding-top: .25rem;
    margin-bottom: 2rem;
    white-space: pre-wrap;
}

.kmk-cta-tile .cta-section a {
    font-family: 'Eksell', sans-serif;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .kmk-cta-tile {
        flex-flow: column;
    }
    .kmk-cta-tile .cta-section { flex-basis: 100%; width: 100%; }
}

/* ================================= KMK MAP TILE ================================ */
.kmk-map-tile {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 2rem;
}

.kmk-map-tile h1 {
    font-family: 'Eksell', sans-serif;
    color: #1E1B1B;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
}

.kmk-map-tile h2 {
    color: #1E1B1B;
    margin-top: 1.5rem;
    white-space: pre-wrap;
    font-family: 'Eksell', sans-serif;
}

@media screen and (max-width: 768px) {
    .kmk-map-tile {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
    .kmk-map-tile h1 {
        padding-left: 4rem;
        padding-right: 2rem;
    }
    .kmk-map-tile h2 {
        padding-left: 4rem;
        padding-right: 2rem;
    }
}

/* ================================= KMK Page Navigator ================================= */

.kmk-page-navigator {
    position: absolute;
    top: 115vh;
    cursor: pointer !important;
    z-index: 1000;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.kmk-page-navigator a:after { content: none; }
.kmk-page-navigator a:before { content: none; }

.kmk-page-navigator a label { cursor: pointer; white-space: nowrap; }

.kmk-page-navigator a label:first-child {
    color: rgb(240, 57, 25);
    padding-right: 1rem;
    position: relative;
}

.kmk-page-navigator a label:first-child:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: rgb(240, 57, 25);
    border-radius: 50%;
    top: calc(50% + 2px);
    right: 4px;
    
}

.navigator-next a {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: absolute;
    right: calc(-100vw - 2rem);
    max-width: 500px;
}

.navigator-prev a {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    position: absolute;
    left: -1.5rem;
}

.navigator-sticky {
    position: fixed;
    top: 48vh;
}

/* ================================= KMK Video Tile ================================= */
.kmk-video-tile {
    padding: 2rem;
    min-height: 480px;
    height: 480px;
    overflow: hidden;
}

.kmk-video-tile div {
    width: 100%;
    height: 100%;
}

.kmk-video-tile iframe {
    width: 100%;
    height: 100% !important;
}
.kmk-video-tile video {
    width: 100%;
    height: inherit;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .kmk-video-tile {
        min-height: 400px;
        height: 400px;
        overflow: hidden;
    }
    .kmk-video-tile video {
        width: auto;
        width: initial;
    }
}

/* ================================= KMK Card Tile Component ================================= */
.kmk-card-tile {
    padding: 0 2rem;
}

.kmk-card-tile h1 {
    overflow: hidden;
    text-overflow: ellipsis;
}

.kmk-card-tile h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin: 1.2rem 0;
}
.kmk-card-tile p {
    line-height: 1.5;
}
.kmk-card-tile .card-tile-container {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}
.kmk-card-tile .card-tile-container > * {
    padding-left: 0 !important;
}
.kmk-card-tile .card-tile-container a {
    text-decoration: underline;
}
.kmk-card-tile .center-container {
    align-items: center;
    justify-content: center;
    padding-right: 0 !important;
}
.kmk-card-tile .center-container .card-tile-container {
    padding-right: 0;
    padding-left: 0;
}
.kmk-card-tile .no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.kmk-card-tile .kmk-card-tile-link {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.kmk-card-tile .kmk-card-tile-link .kmk-card-tile-link-images {
    overflow: hidden;
}
.kmk-card-tile img {
    object-fit: cover;
    max-height: 790px;
    transition: -webkit-transform 400ms ease;
    transition: transform 400ms ease;
    transition: transform 400ms ease, -webkit-transform 400ms ease;
    will-change: transform;
}
.kmk-card-tile-link[href] {
    cursor: pointer;
}
.kmk-card-tile-link[href]:hover img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.kmk-card-tile-link:before, .kmk-card-tile-link:after {
    content: none !important;
}

.kmk-card-tile-link[href] .kmk-card-tile-images img {
    will-change: transform;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.kmk-card-tile-link:hover[href] .kmk-card-tile-images img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.kmk-card-tile-link:hover[href] .card-tile-container a {
    font-weight: 600;
}

.kmk-card-tile-link:not([href]) { cursor: default; }

@media screen and (min-width: 1600px) and (max-width: 2000px) {
    .kmk-card-tile-link-images {
        max-height: 700px;
    }
    .kmk-card-tile img {
        max-height: 700px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .kmk-card-tile-link-images {
        max-height: 590px;
    }
    .kmk-card-tile img {
        max-height: 590px;
    }
}
@media screen and (min-width: 1224px) and (max-width: 1400px) {
    .kmk-card-tile-link-images {
        max-height: 512px;
    }
    .kmk-card-tile img {
        max-height: 512px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1224px) {
    .kmk-card-tile-link-images {
        max-height: 425px;
    }
    .kmk-card-tile img {
        max-height: 425px;
    }
}

@media screen and (max-width: 768px) {
    .kmk-card-tile {
        display: flex;
        flex-flow: column;
    }
    .kmk-card-tile .center-container .card-tile-container {
        padding-left: 0;
        padding-right: 0;
    }
    .kmk-card-tile .center-container img {
        max-height: 450px;
        object-fit: contain;
    }
    .kmk-card-tile .no-padding {
        padding: 0 !important;
    }
    .kmk-card-tile p {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    .kmk-card-tile img {
        width: 100%;
        height: auto !important;
        /*padding-right: 2rem;*/
    }
    .image-align-right {
        flex-flow: column-reverse !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 2rem !important;
    }
    .image-align-left { flex-flow: column-reverse !important; }
    .image-align-left .kmk-image-text-tile-content { margin-bottom: 2rem; }
    .image-align-right .kmk-image-text-tile-content { margin-bottom: 2rem; padding: 0 !important; }
}

/* ================================= KMK Hamburger ================================= */

.kmk-menu-hamburger {
    position: fixed;
    top: 1rem;
    left: 1rem;
}

/* ================================= KMKBlogTile ================================= */
.kmk-blog-tile {
    padding: 2rem 2rem 0 2rem;
}

.kmk-blog-tile h1 {
    /* font-size: 3.05rem; */
    font-family: 'Eksell', sans-serif;
    white-space: pre-wrap;
}
.kmk-blog-summary {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    line-height: 1.5;
    font-weight: 600;
}
.kmk-blog-summary span {
    margin-right: 20px;
}
.kmk-blog-summary img {
    margin-right: 15px;
}
.kmk-blog-summary .avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
}
.kmk-blog-tag {
    text-transform: uppercase;
    margin-right: 5px;
}
.kmk-blog-tag:hover {
    text-decoration: underline;
}

/* ================================= KMKBlogTile ================================= */
.kmk-summary-tile {
    min-height: 400px;
    display: flex;
    flex-flow: row nowrap;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.kmk-summary-tile .kmk-summary-tile-section {
    width: 50%;
    flex-basis: 50%;
}
.kmk-summary-tile .kmk-summary-tile-text-section {
    padding: 2rem;
    line-height: 1.5;
}
.kmk-summary-tile .kmk-summary-tile-section img {
    height: 100%;
    object-fit: cover;
}
.kmk-summary-tile h2 {
    margin-bottom: 1rem;
    white-space: pre-wrap;
    font-size: 3.5rem;
    font-weight: 300;
    font-family: 'Eksell' !important;
}
.kmk-summary-tile p {
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.kmk-summary-tile .kmk-summary-tile-text-section {
    margin: 2rem 0;
    padding-right: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
    margin-left: 2rem;
    margin-top: 4.5rem;
}
.kmk-summary-tile .kmk-summary-tile-buttons-container button {
    margin-top: 1rem;
}
.kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
    margin-top: 7rem;
    margin-left: 2rem;
}
.kmk-summary-tile .kmk-summary-tile-image-section {
    position: relative;
}
.buttons-absolute {
    position: absolute;
    top: 7rem;
    left: 2rem;
    margin-top: 0 !important;
    margin-left: 0 !important;
}
.kmk-summary-tile .button-align-right {
    margin-right: 3rem;
    text-align: right;
}
.kmk-summary-tile .button-align-center {
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.kmk-summary-tile .button-align-left {
    text-align: left;
}
.kmk-summary-tile .button-align-center {
    text-align: center;
}
.kmk-summary-tile .kmk-summary-tile-section .kmk-summary-tile-buttons-container .react-ripples {
    margin-top: 10px;
}
.kmk-summary-tile .kmk-summary-tile-section button {
    display: inline;
    padding: 15px 30px;
    border-radius: 2rem;
    white-space: nowrap;
    font-size: 1rem;
    overflow: hidden;
    font-family: 'Eksell', sans-serif;
}
.kmk-summary-tile .has-images {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 !important;
    padding: 0 !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
/*.kmk-summary-tile-buttons-container {
    margin-top: 6rem;
    margin-left: 8rem;
}*/

@media screen and (max-width: 768px) {
    .kmk-summary-tile {
        display: flex;
        flex-direction: column;
    }
    .additional-margin {
        padding-top: 6rem !important;
    }
    .kmk-summary-tile .kmk-summary-tile-section {
        width: 100%;
        flex-basis: 100%;
    }
    .kmk-summary-tile .kmk-summary-tile-section img {
        min-height: 60vh;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section {
        padding-left: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section {
        margin-top: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section .kmk-summary-tile-buttons-container {
        padding-top: 2rem;
        padding-bottom: 0;
        padding-left: 0;
        margin-left: 0 !important;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
        padding-left: 0rem;
        margin-left: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-text-section .kmk-summary-tile-buttons-container button:last-child {
        margin-bottom: 0;
    }
    .kmk-summary-tile .kmk-summary-tile-image-section .kmk-summary-tile-buttons-container {
        margin-left: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-section button {
        margin-top: 4rem;
    }
    .kmk-summary-tile .kmk-summary-tile-buttons-container {
        margin-top: 0rem;
        margin-left: 4rem;
        padding-bottom: 2rem;
    }
    .kmk-summary-tile .kmk-summary-tile-buttons-container button {
        margin-top: 0;
        margin-bottom: 2rem;
    }
}

/* ================================= Form ====================================== */
.kmk-form-tile {
    padding: 2rem;
    padding-right: 8rem;
}
.kmk-form-post-message {
    font-size: 14px;
    line-height: 1.5;
    padding: .5rem 0;
}
.kmk-form-tile h2 {
    font-size: 3.05rem;
    max-width: 60%;
    margin-bottom: 2rem;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
}

.form-input {
    width: 100%;
    margin-bottom: 1rem;
}
.form-input .form-input-error {
    margin-top: .2rem;
    color: #FF003A;
    font-size: 11px;
    display: block;
    text-align: left;
}
.form-input input {
    background-color: transparent;
    padding: 8px 0;
    display: block;
    width: 100%;
    color: #FFF;
    font-size: 16px;
    border-bottom: 3px solid #FFF;
    transition: all 400ms ease-in-out;
}
.form-input input::-webkit-input-placeholder {
    color: #FFF;
}
.form-input input::-ms-input-placeholder {
    color: #FFF;
}
.form-input input::placeholder {
    color: #FFF;
}
.form-input[data-error="true"] input {
    color: #FF003A;
    border-bottom: 3px solid #FF003A;
    margin-bottom: .5rem;
}
.form-input[data-error="true"] input::-webkit-input-placeholder {
    color: #FF003A;
}
.form-input[data-error="true"] input::-ms-input-placeholder {
    color: #FF003A;
}
.form-input[data-error="true"] input::placeholder {
    color: #FF003A;
}
.form-input textarea {
    min-height: 80px;
    padding: 13px 20px;
    color: #000;
    font-size: 16px;
    background-color: #FFF;
    margin-bottom: 0;
}
.form-input[data-error="true"] textarea {
    background-color: #FF003A;
    color: #FFF;
    margin-bottom: .5rem;
    transition: all 400ms ease-in-out;
}
.form-submit-button-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 2rem;
    margin-top: -.5rem;
}
.form-submit-button-container .form-submit-button {
    color: #FFFFFF;
    opacity: 1;
    padding: 0;
}
.form-submit-button-container .form-submit-button:hover { color: #FFF !important; opacity: 1 !important; }

.kmk-form-textarea {
    border: 1px solid #000;
    background-color: #FFF;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    min-height: 100px;
    padding: 15px;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
    outline: none;
    box-sizing: border-box;
}
.kmk-form-textarea::-webkit-input-placeholder {
    color: #000;
}
.kmk-form-textarea::-ms-input-placeholder {
    color: #000;
}
.kmk-form-textarea::placeholder {
    color: #000;
}
.kmk-form-validation-error {
    color: #F00C21;
    font-size: 12px;
    display: block;
    margin-top: 5px;
    text-align: right;
}
.kmk-form-validation-error:last-child {
    margin-bottom: 20px;
}
.kmk-form-submit-button {
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 1rem;
    color: #FFF;
    transition: all 600ms ease;
}
.kmk-form-submit-button .check {
    width: 18px;
    height: 12px;
}
.submitted {
    background-color: #4B8700;
}

@media screen and (max-width: 768px) {
    .kmk-form-tile {
        padding-right: 2rem;
    }
    .kmk-form-tile h2 {
        max-width: 100%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1350px) {
    .kmk-form-tile h2 {
        max-width: 90%;
    }
}


/* =========================== Radiobutton ============================= */
.kmk-radiobutton-container {
    margin-top: 2rem;
}
.kmk-radiobutton-label{
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: left;
    display: block;
}
.kmk-radiobutton {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
    outline: none;
}
.kmk-radiobutton {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.kmk-radiobutton input:focus, input:hover, input:active {
    outline: none;
}
.kmk-radiobutton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.kmk-radiobutton span {
    position: absolute;
    top: -5px;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 3px solid #F00C21;
}
.kmk-radiobutton span:after {
    content: "";
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #F00C21;
}
.kmk-radiobutton input:checked ~span:after {
    display: block;
}

@media screen and (max-width: 768px) {
    .kmk-radiobutton:hover input:not(:checked) ~span:after {
        display: block;
        opacity: .5;
    }
    .kmk-form-tile h2 {
        max-width: 100%;
    }
}

.kmk-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: left;
    outline: none;
    width: calc(100% - 2rem);
    line-height: 1.5;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.kmk-checkbox .kmk-checkbox-label {
    line-height: 1.7;
}
.kmk-checkbox a {
    text-decoration: underline;
}
.has-error {
    color: #F00C21;
}
.has-error span {
    border: 1px solid #F00C21 !important;
}
.kmk-checkbox input:focus, input:hover, input:active {
    outline: none;
}
.kmk-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.kmk-checkbox span {
    position: absolute;
    top: 5px;
    left: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #000;
}
.kmk-checkbox span:after {
    content: "";
    display: none;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: url(/static/media/checkbox.99933f47.svg);
}
.kmk-checkbox input:checked ~span:after {
    display: block;
}

/* ================================= Buttons Tile ====================================== */

.kmk-buttons-tile {
    padding: 2rem;
}
.kmk-buttons-tile .basic-button {
    padding: 15px 30px;
    border-radius:  30px;
    font-size: 1rem;
    position: relative;
}
.kmk-buttons-tile .with-icon {
    padding-left: 55px;
}
.kmk-buttons-tile .basic-button img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
}

@media screen and (max-width: 768px) {
    .kmk-buttons-tile {
        display: flex;
        flex-flow: column;
        padding: 2rem;
    }
    .kmk-buttons-tile .basic-button {
        margin-bottom: 1rem;
        margin-left: 0 !important;
        margin-right: 1rem;
    }
}

/* ================================= Table of contents ================================= */

.kmk-table-of-contents {
    padding: 2rem;
    font-weight: 600;
    position: relative;
}
.kmk-table-of-contents a {
    line-height: 26px;
    font-weight: 400;
    text-decoration: underline;
}
.kmk-table-of-contents a:hover {
    font-weight: 800;
}
.column-layout {
    display: flex;
    flex-direction: row nowrap;
}
.column-layout div:last-child {
    margin-left: 4rem;
    margin-top: 3rem;
}
@media screen and (max-width: 768px) {
    .column-layout { flex-flow: column; }
    .column-layout div {
        margin: 0 !important;
    }
}

/* ================================= Instagram ================================= */

.kmk-instafeed {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
}
.kmk-instafeed a:nth-child(even) {
    margin: 0 15px;
}
.instagram-feed-tile {
    width: 300px;
    min-width: 300px;
    border: 1px solid #EBECF0;
    border-radius: 6px;
}
.instagram-feed-tile .instagram-feed-tile-header {
    display: flex;
    flex-flow: row nowrap;
    height: 60px;
    align-items: center;
    padding: 10px;
}
.instagram-feed-tile .instagram-feed-tile-header .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.instagram-feed-tile-header .instagram-logo {
    width: 25px;
    height: 25px;
}
.instagram-feed-tile .empty-grow {
    flex-grow: 2;
}
.instagram-feed-tile-header .feed-details {
    display: flex;
    flex-flow: column;
    margin-left: 20px;
}
.instagram-feed-tile-header .feed-details .full-name {
    color: #000;
    font-size: 14px;
}
.instagram-feed-tile-header .feed-details .post-date {
    color: #C1C7D0;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
}
.instagram-feed-tile .feed-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.instagram-feed-tile .feed-manage-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 10px;
}
.instagram-feed-tile .feed-manage-bar .feed-manage-section {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    cursor: pointer;
    margin-right: 20px;
}
.instagram-feed-tile .feed-manage-bar .feed-manage-section img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.instagram-feed-tile .feed-content {
    padding: 0 10px 20px 10px;
    margin-bottom: 25px;
    overflow: hidden;
    /*  height: 225px; */
}
.instagram-feed-tile .feed-content .caption {
    margin-right: 10px;
    overflow-wrap: break-word;
}

/* =================================== Appointly ==================================== */
.kmk-appointly-tile iframe {
    width: 100%;
    min-height: 600px;
}

@media screen and (max-width: 768px) {
    .kmk-instafeed {
        flex-flow: column;
        align-items: center;
    }
    .kmk-instafeed a:nth-child(odd) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.kmk-top-bar-title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    font-family: 'Eksell', sans-serif;
    opacity: 0;
    cursor: pointer;
    -webkit-animation: fade-block 500ms ease 500ms forwards;
            animation: fade-block 500ms ease 500ms forwards;
}
@media screen and (max-width: 768px) {
    .kmk-top-bar-title {
        display: none;
    }
}

/* ================================= ANIMATIONS ================================= */

.show-block-pre { opacity: 0; }
.show-block {
    -webkit-animation: show-block 1600ms ease forwards;
            animation: show-block 1600ms ease forwards;
}

.fade-block-pre { 
    opacity: 0; 
    height: 100%; 
    width: 100%;
    display: flex;
    flex-flow: inherit;
    padding: inherit;
    margin: inherit;
    justify-content: inherit;
    align-items: inherit;
}
.fade-block {
    -webkit-animation: fade-block 1600ms ease forwards;
            animation: fade-block 1600ms ease forwards;
}

@-webkit-keyframes fade-block {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-block {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes show-block {
    0% { opacity: 0; -webkit-transform: translateY(20%); transform: translateY(20%); }
    100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
}

@keyframes show-block {
    0% { opacity: 0; -webkit-transform: translateY(20%); transform: translateY(20%); }
    100% { opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); }
}

.policy-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 1000px;
    min-height: 300px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 35px 44px;
    z-index: 1000;
    color: #000 !important;
    -webkit-animation: fade-in 500ms ease forwards;
            animation: fade-in 500ms ease forwards;
}
.policy-popup-container h2 {
    font-family: 'Eksell', serif !important;
    font-weight: 300 !important;
    margin-bottom: 22px;
    font-size: 24px;
}
.policy-popup-container p {
    font-size: 14px;
    line-height: 1.8;
}
.policy-popup-container .close-popup {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .policy-popup-container {
        min-width: unset;
        width: 95vw;
    }
}
@-webkit-keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.cta-carousel-container {
    min-height: 550px;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}
.cta-carousel-container .cta-carousel-section {
    height: 600px;
    min-height: 600px;
    width: 50%;
    flex-grow: 2;
    flex-basis: 50%;
}
.cta-carousel-additionaltext {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 4rem;
    font-family: 'PT Sans', sans-serif !important;
}
.cta-carousel-container .cta-carousel-section h2 {
    cursor: pointer;
    font-size: 3.05rem;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
    transition: all 700ms ease;
}
.cta-carousel-container button:first-of-type {
    margin-top: 2rem;
}
.cta-carousel-container button {
    padding: 1rem 2rem;
    border-radius: 2rem;
    font-size: 1rem;
}
.cta-carousel-container .image-section {
    padding: 0;
    width: 50px;
    overflow: hidden;
}
.cta-carousel-container .centered-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: inherit;
    min-height: inherit;
}
.cta-carousel-container .centered-section div {
    padding: 2rem;
}
.cta-carousel-container .images-strip img {
    width: inherit;
    object-fit: cover;
    height: inherit;
    min-height: inherit;
}
.cta-carousel-container .images-strip {
    display: flex;
    flex-flow: row nowrap;
    height: inherit;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
}

@media screen and (max-width: 768px) {
    .cta-carousel-container {
        flex-flow: column;
    }
    .cta-carousel-container .cta-carousel-section {
        width: 100%;
        flex-basis: 100%;
    }
    .cta-carousel-container .images-strip img {
        min-width: 100vw;
    }
}
@media screen and (min-width: 1024px) {
    .cta-carousel-container .images-strip img {
        min-width: 50vw;
    }
}
.logo-carousel-container {
    padding: 4rem 2rem; 
    width: 100%;
    max-width: 100vw;
}
.logo-carousel-container h2 {
    font-family: 'Eksell', sans-serif !important;
    margin-bottom: 2rem;
    font-size: 3.05rem;
    text-align: center;
}
.logo-carousel-container .carousel-element {
    background: #FFF;
    width: 170px;
    max-width: 170px;
    height: 100px;
    max-height: 100px;
}
.logo-carousel-container .carousel-element img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .logo-carousel-container {
        padding: 2rem 0;
    }
    .logo-carousel-container h2 {
        padding: 0 2rem;
    }
}
/* ======= ANIMATIONS ====== */
@-webkit-keyframes slide-in-left {
    from {
        opacity: 0;
        -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes slide-in-left {
    from {
        opacity: 0;
        -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@-webkit-keyframes slide-in-bottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes slide-in-bottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@-webkit-keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* ======= MISC ====== */
.animated-content {
    -webkit-animation: slide-in-bottom 2000ms ease forwards;
            animation: slide-in-bottom 2000ms ease forwards;
}
.col-6 { position: relative; }
.overlay-logo {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    z-index: 100;
    width: 65px !important;
    height: 65px;
    max-height: 65px;
    max-width: 60px !important;
    -webkit-animation: fade-in 1s ease-in-out forwards;
            animation: fade-in 1s ease-in-out forwards;
}
.overlay-logo img { min-height: 60px !important; }
.kmk-image-tile .stack-images-1 .overlay-logo img { min-height: 60px !important; }
@media screen and (max-width: 768px) {
    .overlay-logo {
        bottom: 1rem;
        right: 1rem;
        width: 45px !important;
        height: 45px;
        max-height: 45px;
        max-width: 45px;
    }
    .overlay-logo img {
        width: 45px !important;
        min-height: 45px !important;
    }
}

/* ======= AF HEADER ======= */
.af-header {
    width: 100vw;
    /*max-height: 80vh;*/
}
.af-header .af-header-background {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
.af-header .af-header-background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-header .text-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
    max-width: 48%;
    margin-right: 36px;
    -webkit-animation: slide-in-left 2000ms ease forwards;
            animation: slide-in-left 2000ms ease forwards;
}
.af-header .image-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
}
.af-header .image-container img {
    max-width: unset;
    max-height: 400px;
    position: relative;
}
.af-header .image-container .slick-slider {
    max-height: 400px;
    max-width: 800px;
}
.af-header .image-container .slick-slider img {
    object-fit: contain !important;
}
.af-header h1 {
    color: #FFF;
    font-weight: 800;
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.af-header p {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 28px;
    white-space: pre-wrap;
}
@media screen and (max-width: 500px) {
    .af-header h1 { line-height: 35px !important; }
    .af-header p { line-height: 24px !important; }
}
@media screen and (max-width: 400px) {
    .af-header .text-container { margin-right: 0; }
}
@media screen and (max-width: 800px) {
    .af-header .image-container { display: none; }
    .af-header .af-header-background .text-container { max-width: 500px; }
}
@media screen and (max-width: 1025px) {
    .af-header { height: unset; }
    .af-header .af-header-background { padding-top: 7rem !important; }
    .af-header .af-header-background .text-container { margin-bottom: 1rem; }
    .af-header .af-header-background { flex-flow: row wrap; padding: 2rem; }
    .af-header .af-header-background .text-container h2 { font-size: 30px; }
    .af-header .af-header-background .text-container p { font-size: 16px; }
}
@media screen and (min-width: 900px) and (max-width: 1150px) {
    .af-header .image-container .slick-slider {
        max-width: 500px;
    }
}
@media screen and (min-width: 1030px) and (max-width: 1550px) {
    .af-header .af-header-background .text-container {
        min-width: 40vw;
        padding: 8rem 0rem 6rem 0rem;
    }
    .af-header .image-container { max-width: 50vw; }
}
@media screen and (min-width: 1550px) {
    .af-header { height: 70vh; }
}


/* ======= AF HEADER HOVER ======= */
.af-header-hover {
    width: 100vw;
}
.af-header-hover .af-header-background {
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
.af-header-hover .mouse-detector {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    z-index: 1;
}
.af-header-hover .af-background-layer-top {
    opacity: 0;
    transition: opacity 500ms ease;
}
.af-header-hover .af-background-layer-top[data-active="true"] {
    opacity: 1;
}
.af-header-hover .af-header-background img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-header-hover .text-container {
    flex-grow: 1;
    flex-basis: 50%;
    z-index: 1;
    max-width: 48%;
    margin-right: 36px;
    -webkit-animation: slide-in-left 2000ms ease forwards;
            animation: slide-in-left 2000ms ease forwards;
}
.af-header-hover h1 {
    color: #FFF;
    font-weight: 800;
    margin-bottom: 1rem;
    white-space: pre-wrap;
}
.af-header-hover p {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 28px;
    white-space: pre-wrap;
}
.af-header-hover .logo {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    min-width: 30px;
    min-height: 30px;
    z-index: 100;
}
@media screen and (max-width: 500px) {
    .af-header-hover h1 { line-height: 35px !important; }
    .af-header-hover p { line-height: 24px !important; }
}
@media screen and (max-width: 400px) {
    .af-header-hover .text-container { margin-right: 0; }
}
@media screen and (max-width: 800px) {
    .af-header-hover h1 { white-space: normal; }
    .af-header-hover .af-header-background .text-container { max-width: 500px; }
}
@media screen and (max-width: 1025px) {
    .af-header-hover { height: unset; }
    .af-header-hover .af-header-background { padding-top: 7rem !important; }
    .af-header-hover .af-header-background .text-container { margin-bottom: 1rem; }
    .af-header-hover .af-header-background { flex-flow: row wrap; padding: 2rem; }
    .af-header-hover .af-header-background .text-container h2 { font-size: 30px; }
    .af-header-hover .af-header-background .text-container p { font-size: 16px; max-width: 55%; }
}
@media screen and (min-width: 1030px) and (max-width: 1550px) {
    .af-header-hover .af-header-background .text-container {
        min-width: 40vw;
        padding: 8rem 0rem 6rem 0rem;
    }
}
@media screen and (min-width: 1550px) {
    .af-header-hover { height: 70vh; }
}

/* ======= AF WORLDWIDE ======= */
.af-worldwide {
    display: flex;
    padding: 4rem 2rem;
}
.af-worldwide .list-container, .map-container {
    display: flex;
    align-items: center;
}
.af-worldwide .list-container, .map-container img {
    width: 700px;
}
.af-worldwide .list-container {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 800px;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    display: block;
}
.af-worldwide .list-container label {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 2rem;
    max-width: 60%;
    line-height: 28px;
}
.af-worldwide .map-container {
    flex-grow: 1;
    flex-basis: 50%;
    width: 700px;
    min-width: 700px;
    max-width: 700px;
    height: 700px;
    min-height: 700px;
    max-height: 700px;
    justify-content: center;
    position: relative;
}
.af-worldwide .list-container ul {
    list-style-type: none;
    margin: 0 !important;
    margin-top: 2rem !important;
    padding: 0;
}
.af-worldwide .list-container ul li {
    font-size: 42px;
    font-weight: 800;
    white-space: nowrap;
    position: relative;
    line-height: 55px;
    cursor: pointer;
}
.af-worldwide .list-container ul li span { position: relative; }
.af-worldwide .list-container ul li span:after {
    position: absolute;
    bottom: -.2rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.af-worldwide .list-container ul li[data-active="true"] span:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}
.af-worldwide .map-container .map-marker-container {
    position: absolute;
}
.af-worldwide .map-container .map-marker-container .map-marker {
    width: 60px;
    height: 60px;
    opacity: .4;
    cursor: pointer;
    transition: opacity 500ms ease;
}
.af-worldwide .map-container .map-marker-container .map-marker:hover {
    opacity: .5;
}
.af-worldwide .map-container .map-marker-container .map-marker-info {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
    opacity: 0;
    position: absolute;
    top: 100%;
    min-width: 200px;
    transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
}
.af-worldwide .map-container .map-marker-container[data-active="true"] .map-marker-info {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
.af-worldwide .map-container .map-marker-container[data-active="true"] .map-marker {
    opacity: 1;
}
.origin-left { left: 0; }
.origin-right { left: unset !important; right: 0 !important; }
@media screen and (min-width: 768px) and (max-width: 1025px) {
    .af-worldwide .list-container label {
        font-size: 16px;
        max-width: 100%;
    }
}
@media screen and (max-width: 920px) {
    .af-worldwide {
        padding: 2rem;
        flex-flow: column;
        position: relative;
        max-height: 95vh;
    }
    .af-worldwide .list-container {
        max-width: 100%;
        padding-bottom: 1rem;
    }
    .af-worldwide .list-container label { font-size: 18px;}
    .af-worldwide .list-container ul li { font-size: 30px; line-height: 45px; }
    .af-worldwide .list-container ul li:after { height: 3px; }
    .af-worldwide .map-container {
        min-width: unset;
        -webkit-transform-origin: left top;
                transform-origin: left top;
        -webkit-transform: scale(.75);
                transform: scale(.75);
    }
}



/* ======= AF Card ======= */
.af-card {
    padding: 2rem;
}
.af-card .af-card-swiper {
    display: flex;
    position: relative;
}
.af-card .card-list {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    flex-basis: 50%;
    color: #FFF;
    margin-right: 2rem;
}
.af-card .card-list p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}
.af-card .card-list ul {
    margin: 1rem 0;
    padding: 0;
    list-style-type: none;
}
.af-card .card-list ul li {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    cursor: pointer;
}
.af-card .card-list ul li h2 { font-weight: 600; }
.af-card .card-list ul li span { position: relative; }
.af-card .card-list ul li span {
    text-decoration: none;
    background-image: linear-gradient(#FFF, #FFF);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 3px;
    transition: background-size .3s;
}
.af-card .card-list ul li[data-active="true"] span {
    /*transform: scaleX(1);*/
    background-size: 100% 3px;
}
.af-card .card-content {
    flex-grow: 1;
    flex-basis: 50%;
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    min-height: 265px;
    opacity: 1;
}
.af-card .card-content[data-animate="true"] {
    -webkit-animation: show-card 500ms ease forwards;
            animation: show-card 500ms ease forwards;
}
@-webkit-keyframes show-card {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(10%);
                transform: translateY(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes show-card {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(10%);
                transform: translateY(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .af-card { flex-flow: row; }
    .af-card .card-list p { font-size: 18px; }
    .af-card .card-list ul li { font-size: 30px; }
    .af-card .card-list ul li span:after { height: 3px; }
    .af-card .card-content {
        font-size: 18px;
    }
}
@media screen and (max-width: 767px) {
    .af-card .af-card-swiper { flex-flow: row wrap; padding-bottom: 4rem !important; margin-bottom: -4rem; }
    .af-card .card-list p { font-size: 16px; line-height: 24px; }
    .af-card .card-list ul li { font-size: 24px; line-height: 38px; }
    .af-card .card-list ul li span:after { height: 3px; }
    .af-card .card-content {
        margin-top: 1rem !important;
        font-size: 16px;
        line-height: 22px;
    }
    .af-card .card-list ul li[data-active="false"] {
        display: none !important;
    }
}




/* ======= AF CTA ======= */
.af-cta {}
.af-cta input[type="button"] {
    background-color: #000;
    color: #FFF !important;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 19px 41px;
    position: relative;
    transition: opacity 400ms ease-in-out;
}
.af-cta input[type="button"]:hover {
    opacity: 0.8;
}
.af-cta-wrapper {
    display: flex;
}
.af-cta-wrapper .text-container, .image-container {
    position: relative;
    flex-grow: 1;
    flex-basis: 50%;
    width: 100%;
}
.af-cta-wrapper .text-container, .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.af-cta-wrapper .text-container {
    padding: 4rem 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.af-cta-wrapper .text-container div { max-width: 80%; }
.af-cta-wrapper .text-container *[style*="font-size:42px"] { font-size: 42px; line-height: 55px !important; }
.af-cta-wrapper .text-container p { line-height: 1.3; }
.align-left { flex-flow: row-reverse; }
.align-right{ flex-flow: row; }
.af-cta-wrapper .text-container .buttons-container { margin-top: 2rem; }
.af-cta-wrapper .text-container .buttons-container button { white-space: nowrap; }

@media screen and (max-width: 400px) {
    .af-cta-background {
        padding: 6rem 0rem 4rem 2rem !important;
    }
}
@media screen and (max-width: 768px) {
    .af-cta-wrapper {
        flex-flow: column-reverse;
    }
    .af-cta-wrapper .text-container {
        padding: 3rem 2rem;
    }
    .af-cta-wrapper .image-container img {
        max-height: 400px;
    }
    .af-cta-wrapper .text-container .buttons-container { margin-top: 2rem; }
    .af-cta-wrapper .text-container p *[style*="font-size:42px"] { font-size: 30px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:22px"] { font-size: 18px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:20px"] { font-size: 16px !important; line-height: 22px !important; font-weight: 400 !important; }
    .af-cta-wrapper .text-container *[style*="line-height:28px"] { font-size: 16px !important; line-height: 22px !important; font-weight: 400 !important; }
    .af-cta-background .text-container p *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-cta-background .text-container *[style*="font-size:22px"] { font-size: 18px !important; }
    .af-cta-wrapper .text-container *[style*="font-size:42px"] { line-height: 38px !important; }
    .af-cta-wrapper .text-container *[style*="line-height:48px"] { line-height: 38px !important; }
}
@media screen and (min-width: 1550px) {
    .af-cta-height-70 { height: 70vh; }
    .af-cta-height-70 .af-cta-wrapper { height: 100%; }
}
@media screen and (max-width: 768px) {
    .af-cta-height-70 .af-cta-wrapper {
        flex-flow: column;
    }
    .af-cta-height-70 .af-cta-wrapper .image-container img {
        max-height: 600px;
        min-height: 220px;
        object-fit: cover;
    }
}

/* ======= AF Html ======= */
.af-html {
    padding: 2rem;
}
.af-html .container-flex {
    display: flex;
}
.af-html .container-flex .container-left {
    margin-right: 4rem;
}
.af-html .container-flex .container-left, .container-right {
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-html input[type="button"] {
    background-color: #000;
    color: #FFF !important;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 19px 41px;
    position: relative;
}
@media screen and (max-width: 768px) {
    .af-html {
        padding: 3rem 2rem 2rem 2rem !important;
    }
    .af-html *[style*="font-size:20px"] {
        font-size: 16px !important;
    }
    .af-html span[style^="font-size:4"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-html *[style*="line-height:48px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-html *[style^="line-height:5"] { line-height: 30px !important; }
    .af-html *[style*="font-size:42px"] { font-size: 30px !important; }
    .af-html .container-flex { flex-flow: column; }
    .af-html .container-left, .container-right { margin-bottom: 2rem; }
}


/* ======= AF Footer ======= */
.af-footer {
    background-color: #000;
    width: 100vw;
    padding: 3rem 2rem;
    color: #FFF;
    display: flex;
}
.af-footer .grow-1 { flex-grow: 1; }
.af-footer .grow-2 { flex-grow: 2; }
.af-footer .grow-3 { flex-grow: 3; }
.af-footer .footer-section {
    margin-right: 3rem;
    font-weight: 800;
}
.af-footer .footer-section .footer-section-contact-header {
    font-size: 42px;
    font-weight: 800;
}
.af-footer .footer-section p {
    margin: 1rem 0;
    font-size: 22px;
}
.af-footer .form-post-message {
    font-size: 17px !important;
    margin-top: .5rem !important;
}
.af-footer .footer-svg-logo {
    width: 150px;
}
.af-footer .icons-container-wrapper {
    display: flex;
    flex-flow: column;
    font-weight: 600;
}
.af-footer .icons-container-wrapper label {
    text-align: left;
    line-height: 1.5;
}
.af-footer .icons-container-wrapper .icons-container {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 5px;
    margin-bottom: 15px;
}
.af-footer .icons-container-wrapper .icons-container a {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #FFF;
    background-position: center;
    margin-right: 19px;
}
.af-footer .icons-container-wrapper .icons-container a:hover {
    background-color: #F2F2F2;
}
.af-footer .form-container-wrapper {
    display: flex;
    flex-flow: column;
    text-align: left;
}
.af-footer .form-container-wrapper .form-desc {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.af-footer .form-container-wrapper .form-label {
    position: relative;
    font-size: 20px;
    text-align: left;
    margin-top: 10px;
}
.af-footer .form-container-wrapper .form-input input {
    border: 1px solid #FFF;
    border-radius: 0;
    padding: 8px 20px;
}
.af-footer .form-container-wrapper .form-label:after {
    width: 100%;
    height: 2px;
    background-color: #FFF;
    position: absolute;
    bottom: -.5rem;
    left: 0;
}
.af-footer .autodesk-link {
    font-weight: 400;
    font-size: 12px;
    text-decoration: underline;
}
.af-footer .form-container-wrapper textarea {
    resize: none;
    border-radius: 0;
}
@media screen and (max-width: 768px) {
    .af-footer { flex-flow: column; }
    .af-footer .form-post-message {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .af-footer .footer-section .footer-section-contact-header { font-size: 30px; }
    .af-footer .footer-section p { font-size: 18px; }
    .af-footer .footer-section { margin-bottom: 2rem; margin-right: 0; }
    .af-footer .form-container-wrapper .form-desc { font-size: 18px; }
    .af-footer .grow-3 { margin-top: 2rem; }
}


/* ======= AF Text ======= */
.af-text {
    padding: 2rem;
}
.af-text h2 {
    font-weight: 600;
    margin-bottom: 1rem;
}
.af-text p {
    font-weight: 400;
    max-width: 50%;
}



/* ======= AF Button ======= */
.af-button {}
.af-button .af-buttons-wrapper {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    height: 100%;
}
.af-button .af-buttons-wrapper button {
    height: 60px;
}
@media screen and (max-width: 768px) {
    .af-button .af-buttons-wrapper {
        padding: 2rem;
    }
}


/* ======= AF CTA Background ======= */
.af-cta-background {
    position: relative;
    padding: 2rem;
}
.af-cta-background .text-container {
    z-index: 100;
    max-width: 39%;
    position: relative;
    -webkit-animation: slide-in-left 2000ms ease forwards;
            animation: slide-in-left 2000ms ease forwards;
}
.af-cta-background .buttons-container {
    margin-top: 2rem;
}
.af-cta-background .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.af-cta-background .background-image img {
    object-fit: cover;
    height: 100%;
}
@media screen and (max-width: 400px) {
    .af-cta-background .text-container {
        padding-right: 1rem;
    }
}
@media screen and (max-width: 768px) {
    .af-cta-background .text-container {
        max-width: 80%;
    }
}
@media screen and (min-width: 1550px) {
    .height-70 {
        height: 70vh;
    }

    .height-70 {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}


/* ======= AF Timer ======= */
.af-timer {
    display: flex;
    align-items: flex-start;
    padding: 6rem 2rem;
}
.af-timer .align-top {
    justify-content: flex-start !important;
}
.af-timer .timer-section {
    display: flex;
    flex-flow: column;
    flex-grow: 2;
    flex-basis: 50%;
    justify-content: center;
    margin-right: 3rem;
}
.af-timer .timer-section .gmt {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 1rem;
}
.af-timer .timer-section .label {
    font-size: 42px;
    font-weight: 800;
    text-align: left;
    line-height: 48px;
}
.af-timer .timer-section .localization {
    font-size: 20px;
    text-align: left;
    font-weight: 800;
    margin-bottom: 1rem;
}
.af-timer .timer-section .timer-wrapper {
    font-size: 150px;
    font-weight: 800;
    white-space: nowrap;
}
@media screen and (min-width: 768px) and (max-width: 1450px) {
    .af-timer .timer-section .timer-wrapper {
        font-size: 80px;
    }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
    .af-timer .timer-section .label { font-size: 30px; }
    .af-timer .timer-section .timer-wrapper { font-size: 60px; }
}
@media screen and (max-width: 767px) {
    .af-timer { flex-flow: column; }
    .af-timer .timer-section { flex-basis: unset; }
    .af-timer .timer-section .label { font-size: 30px; line-height: 38px; }
    .af-timer .timer-section .gmt { font-size: 16px; }
    .af-timer .timer-section .localization { font-size: 18px; }
    .af-timer .timer-section .timer-wrapper { font-size: 55px; }
    .af-timer .align-top { margin-bottom: 2rem; }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
    .af-timer .timer-section .timer-wrapper { font-size: 45px; }
}



/* ======= AF Timer ======= */
.af-cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #000;
    color: #FFF;
    display: flex;
    /*flex-flow: row nowrap;*/
    flex-flow: column;
    padding: 40px;
    z-index: 10000;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-animation: show-cookies-banner 800ms 2000ms forwards;
            animation: show-cookies-banner 800ms 2000ms forwards;
}
.af-cookies-banner .banner-section {
    flex-grow: 2;
    flex-basis: 50%;
    background-color: #000;
    line-height: 22px;
    margin-right: 2rem;
}
.af-cookies-banner .banner-section label { font-size: 16px !important; }
.af-cookies-banner .close-banner {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 1rem;
    height: 1rem;
}
.af-cookies-banner .banner-section .buttons-container {
    display: flex;
}
.af-cookies-banner .banner-section .buttons-container button {
    padding: 10px 25px;
}
.af-cookies-banner .banner-section .buttons-container button:first-child {
    background-color: #FFF;
    color: #000;
}
.af-cookies-banner .banner-section .buttons-container button:first-child:hover {
    color: #000 !important;
    background-color: #FFF !important;
}
.af-cookies-banner .banner-section .buttons-container button:last-child:hover {
    color: #FFF !important;
}
@-webkit-keyframes show-cookies-banner {
    from { -webkit-transform: translateY(100%); transform: translateY(100%); }
    to { -webkit-transform: translateY(0); transform: translateY(0); }
}
@keyframes show-cookies-banner {
    from { -webkit-transform: translateY(100%); transform: translateY(100%); }
    to { -webkit-transform: translateY(0); transform: translateY(0); }
}
@media screen and (max-width: 768px) {
    .af-cookies-banner { flex-flow: row wrap; padding: 2rem 2rem; }
    .af-cookies-banner .buttons-container { margin-top: 1rem; }
}


/* ======= AF Persons ======= */
.af-persons {
    padding: 2rem;
}
.af-persons img {
    width: auto !important;
    max-width: 100%;
    min-height: 320px;
    margin-bottom: 1.5rem;
}
.af-persons ul {
    margin-left: 0 !important;
    padding-left: 1.3rem !important;
}
.af-persons li {
    line-height: 28px;
}

@media screen and (max-width: 768px) {
    .af-persons br { margin: 0 !important; line-height: 0 !important; display: none; }
    .af-persons img {
        min-height: 220px;
    }
    .af-persons span[style="font-size:35px; line-height:40px"] {
        font-size: 24px !important;
        line-height: 38px !important;
    }
    .af-persons span[style="font-size:20px; line-height:28px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    .af-persons ul {
        margin-left: 0 !important;
        padding-left: 1.5rem !important;
    }
    .af-persons li * {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}


/* ======= AF Text Carousel ======== */
.af-text-carousel {
    display: flex;
    padding: 4rem 2rem;
}
.af-text-carousel .animated-content { width: 100%; }
.af-text-carousel .html-content-container {
    line-height: 32px;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-text-carousel .html-content-container *[style*="font-size:42px"] {
    line-height: 55px;
}
.af-text-carousel .carousel-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-text-carousel .carousel-container ul {
    list-style-type: none;
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
    padding-left: 0;
    min-height: 185px;
    position: relative; width: 100%;
}
.af-text-carousel .carousel-container ul li {
    position: absolute;
    font-size: 20px;
    line-height: 28px;
    opacity: 0;
    text-align: center;
    white-space: pre-wrap;
    width: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation: hide-li 500ms ease forwards;
            animation: hide-li 500ms ease forwards;
}
.af-text-carousel .carousel-container ul li[data-active="true"] {
    -webkit-animation: show-li 500ms ease forwards !important;
            animation: show-li 500ms ease forwards !important;
}
.af-text-carousel .carousel-container li h1 { font-weight: 600; }
@-webkit-keyframes hide-li {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
                transform: translateY(-40px);
    }
}
@keyframes hide-li {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
                transform: translateY(-40px);
    }
}
@-webkit-keyframes show-li {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes show-li {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@media screen and (max-width: 768px) {
    .af-text-carousel {
        flex-flow: column;
        padding: 2.5rem 2rem;
    }
    .af-text-carousel .carousel-container {
        padding-top: 0;
    }
    .af-text-carousel .carousel-container ul {
        padding: 0;
        min-height: 260px;
        transition: height 200ms ease-in-out;
    }
    .af-text-carousel .carousel-container ul li {
        font-size: 16px;
        line-height: 22px;
    }
    .af-text-carousel .carousel-container ul li h1 {
        font-size: 30px;
        line-height: 38px !important;
    }
    .af-text-carousel .html-content-container p { font-size: 16px !important; line-height: 22px; }
    .af-text-carousel .html-content-container p span[style*="font-size:20px"] { font-size: 16px !important; }
    .af-text-carousel .html-content-container p span[style*="line-height:48px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-text-carousel .html-content-container *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-text-carousel .html-content-container *[style*="line-height:48px"] { line-height: 38px !important; }
}


/* ======= AF Video Banner ======= */
.pure-af-video-banner-container {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    position: relative;
    background-color: black;
    height: 60vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}
.pure-af-video-banner-container .content-container h2 {
    white-space: pre-wrap;
    font-weight: 600;
    margin-bottom: 10px;
}
.pure-af-video-banner-container .content-container p {
    white-space: pre-wrap;
    margin-bottom: 40px;
}
.pure-af-video-banner-container .content-container {
    max-width: 600px;
    position: relative;
    z-index: 2;
}
.pure-af-video-banner-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.pure-af-video-banner-container .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
    transition: background-color 600ms ease-in;
}

@media screen and (max-width: 768px) {
    .pure-af-video-banner-container {
        height: 100%;
        padding: 3rem 2rem;
    }
    .pure-af-video-banner-container h2 {
        font-size: 30px;
        line-height: 35px !important;
        margin-bottom: 30px !important;
    }
    .pure-af-video-banner-container p {
        font-size: 16px;
        line-height: 24px !important;
        margin-bottom: 25px !important;
    }
}

/* ======= KMK Image Override ======= */
@media screen and (max-width: 600px) {
    .kmk-image-tile .stack-images-1 img {
        min-height: 300px !important;
    }
}


/* ======= AF Responsive Banner ======= */
.af-responsive-banner img {
    height: 100%;
}

/* ======= AF Image Carousel ======= */
.af-image-carousel .af-image-carousel-swiper {
    display: flex;
    flex-flow: row;
}
.af-image-carousel p {
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 28px;
}
.af-image-carousel .carousel-container {
    padding: 3rem 2rem;
    width: 50%;
    flex-grow: 2;
    flex-basis: 50%;
}
.af-image-carousel .image-container {
    flex-basis: 50%;
    flex-grow: 1;
    max-width: 50%;
    min-height: 500px;
    overflow: hidden;
    position: relative;
}
.af-image-carousel .image-container .image-container-element {
    position: relative;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}
.af-image-carousel .image-container .image-html-container {
    padding: 2rem;
    max-width: 400px;
    position: absolute;
    top: 1rem;
    left: 0;
}
.af-image-carousel .image-container .image-html-container h1 {
    font-weight: 600;
}
.af-image-carousel .images-strip {
    position: relative;
    display: flex;
    flex-flow: row;
    height: 100%;
    transition: -webkit-transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out, -webkit-transform 600ms ease-in-out;
}
.af-image-carousel .images-strip .image-container-element {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
.af-image-carousel .images-strip img {
    object-fit: cover;
    object-position: left;
    width: 100%;
}
.af-image-carousel .carousel-container ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    font-size: 42px;
    line-height: 55px;
    font-weight: 600;
    position: relative;
}
.af-image-carousel .carousel-container li { cursor: pointer; }
.af-image-carousel .carousel-container li i + span:before { background-color: red; }
.af-image-carousel .carousel-container li span { font-weight: 600; position: relative; }
.af-image-carousel .carousel-container li span:before {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--underline-color);
    -webkit-transform-origin: left;
            transform-origin: left;
    transition: -webkit-transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out, -webkit-transform 600ms ease-in-out;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
}
.af-image-carousel .carousel-container li[data-active="true"] span:before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}
@media screen and (max-width: 768px) {
    .af-image-carousel .af-image-carousel-swiper  {
        flex-flow: column;
    }
    .af-image-carousel .image-container {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        min-height: 400px;
        margin-bottom: 2rem;
    }
    .af-image-carousel .image-container .image-html-container {
    }
    .af-image-carousel .image-container .linear-gradient {
        display: none;
    }
    .af-image-carousel .image-container img {
        object-fit: cover;
        max-height: unset !important;
    }
    .af-image-carousel .carousel-container {
        width: 100%;
        flex-basis: 50%;
        max-height: 180px;
        padding: 3rem 2rem 1rem 2rem;
    }
    .af-image-carousel p {
        font-size: 16px;
        line-height: 24px;
    }
    .af-image-carousel .carousel-container ul {
        margin: 20px 0;
    }
    .af-image-carousel .carousel-container li {
        font-size: 24px;
        line-height: 32px;
    }
    .af-image-carousel .carousel-container li span:before { content: none; }
    .af-image-carousel .carousel-container li[data-active="false"] {
        display: none;
    }
    .af-image-carousel .images-strip .image-container-element h1 { font-size: 30px !important; line-height: 38px !important; }
    .af-image-carousel .images-strip .image-container-element *[style*="border-bottom:1px solid"] { margin-bottom: 1rem; }
}

/* ============= AF MAP ============== */
.af-map { position: relative; }
.af-map .af-map-label {
    position: absolute;
    top: 72px;
    left: 37px;
    z-index: 100;
    color: #FFF;
}
@media screen and (max-width: 768px) {
    .af-map .af-map-label {
        top: 2rem;
        left: 2rem;
    }
    .af-map .af-map-label *[style*="font-size:48px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-map .af-map-label *[style*="font-size:18px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

/* ============= AF Column Html ============== */
.af-column-html {
    padding: 4.5rem 2rem;
    display: flex;
    flex-flow: row nowrap;
}
.af-column-html div { flex-grow: 2; flex-basis: 50%; }
@media screen and (max-width: 768px) {
    .af-column-html {
        flex-flow: column;
        padding: 3.5rem 2rem;
    }
    .af-column-html div:last-child {
        padding-bottom: 1rem;
    }
    .af-column-html *[style*="font-size:42px"] {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .af-column-html *[style*="font-size:20px"] {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

/* ============= AF Image Transition ============== */
.af-image-transition {}
.af-image-transition div:first-child {
    position: relative;;
    min-height: 700px;
}
.af-image-transition img:first-child { opacity: 1; }
.af-image-transition img {
    opacity: 0;
    position: absolute;
    height: 100%;
    object-fit: contain;
    transition: opacity 600ms ease;
}
@media screen and (max-width: 768px) {
    .af-image-transition div:first-child { min-height: 200px !important; }
    .af-image-transition img { object-fit: cover !important; }
    .af-image-transition .overlay-logo {
        max-width: 45px !important;
        max-height: 45px;
        height: 45px;
        bottom: 1rem;
        right: 1rem;
    }
    .af-image-transition .overlay-logo img {
        width: 45px !important;
        min-height: 45px !important;
        max-height: 45px !important;
    }
}


/* ============= AF Slider Inidicator ============ */
.slider-indicator {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.slider-indicator .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}
.slider-indicator .dot i {
    display: block;
    border-radius: 50%;
    background-color: #474747;
    width: 8px;
    height: 8px;
    max-width: 8px;
    transition: background-color 800ms ease-in-out;
}
.slider-indicator .dot[data-active="true"] i {
    background-color: #FFF;
}
@media screen and (min-width: 768px) {
    .slider-indicator { display: none; }
}


/* ============= AF Booklet ============ */
.af-booklet {}
.af-booklet .af-booklet-swiper {
    display: flex;
    flex-flow: row nowrap;
}
.af-booklet .af-booklet-swiper .text-container {
    padding: 4rem 2rem 2rem 2rem;
    flex-basis: 50%;
    color: #FFF;
    min-height: 550px;
}
.af-booklet .af-booklet-swiper .text-container .slider-indicator {
    position: absolute;
    -webkit-transform: none;
            transform: none;
    bottom: 2rem;
    left: unset;
    margin-top: 25px;
    display: flex;
}
.af-booklet .af-booklet-swiper .image-container {
    width: 50%;
    flex-basis: 50%;
    flex-grow: 2;
    overflow: hidden;
}
.af-booklet .af-booklet-swiper .text-container {
    position: relative;
}
.af-booklet .af-booklet-swiper .text-container .text-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4rem 2rem;
}
.af-booklet .af-booklet-swiper .text-container label { display: block; font-weight: 600; margin-bottom: 25px; }
.af-booklet .af-booklet-swiper .text-container h2 {
    font-weight: 600;
    font-size: 32px !important;
    line-height: 48px !important;
    max-width: 80%;
    margin-bottom: 40px;
    padding-right: 4rem;
}
.af-booklet .af-booklet-swiper .text-container p {
    padding-right: 4rem;
    max-width: 80%;
}
.af-booklet .af-booklet-swiper .text-container h2 span { position: relative }
.af-booklet .af-booklet-swiper .image-container {
    position: relative;
}
.af-booklet .af-booklet-swiper .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 500ms ease-in-out;
}
@media screen and (max-width: 768px) {
    .af-booklet .af-booklet-swiper {
        flex-flow: column;
    }
    .af-booklet .af-booklet-swiper .image-container {
        height: 300px;
        width: 100%;
        min-height: 300px;
    }
    .af-booklet .af-booklet-swiper .text-card h2 {
        max-width: 100%;
        padding-right: 0;
    }
}


/* ============= AF Video ============ */
.af-video .af-video-wrapper {
    display: flex;
    flex-flow: row;
}
.af-video .wrapper-align-right {
    flex-flow: row-reverse;
}
.af-video .video-container { 
    flex-basis: 50%; 
    width: 50%;
    overflow: hidden;
}
.af-video .video-container video { width: 100%; height: 100%; min-height: 550px; object-fit: cover; }
.af-video .text-container { flex-basis: 50%; width: 50%; padding: 4rem 2rem; }
.af-video .text-container *[style*="font-size:42px"] { line-height: 48px; }
.af-video .text-container *[style*="font-size:20px"] { line-height: 28px; }
@media screen and (max-width: 768px) {
    .af-video .af-video-wrapper { flex-flow: column; }
    .af-video .wrapper-align-right { flex-flow: column-reverse; }
    .af-video .text-container, .af-video .video-container {
        width: 100%;
        flex-basis: 100%;
    }
    .af-video .text-container *[style*="font-size:42px"] { font-size: 30px !important; line-height: 38px !important; }
    .af-video .text-container *[style*="font-size:20px"] { font-size: 16px !important; line-height: 22px !important; }
}

.page-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 800px;
    min-height: 400px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 35px 44px;
    z-index: 1000;
    color: #000 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation: fade-in 500ms ease forwards;
            animation: fade-in 500ms ease forwards;
}
.page-popup-container h2 {
    font-size: 50px;
    color: #FFF;
    font-family: 'Eksell', serif !important;
}
.page-popup-container button {
    opacity: 0;
    -webkit-animation: fade-in 400ms ease forwards;
            animation: fade-in 400ms ease forwards;
}
.page-popup-container button, .page-popup-container h2 {
    position: absolute;
}
.page-popup-container .close-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .page-popup-container {
        width: 90vw;
        min-height: 600px;
        min-width: unset;
        background-size: cover;
        background-position: 85%;
    }
}

@-webkit-keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.blog-page {
    padding: 6rem 2rem;
}
.blog-tile {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    width: calc(33% - 2rem);
    margin-bottom: 2rem;
}
.blog-tile .thumbnail {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-color: #F4F5F7;
}
.blog-tile:not(:nth-child(2)) {
    padding-left: 2rem;
}
.blog-tile h1 {
    margin-top: 2rem;
}
.blog-tile h2 {
    margin-top: 1rem;
    line-height: 1;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
}
.blog-tile p {
    font-size: 1rem;
    font-weight: 400;
    max-height: 300px;
    margin: 1rem 0;
}
.blog-tile .blog-tile-details {
    margin-top: 1rem;
    font-weight: 800;
    font-size: .8rem;
}
.blog-tile .blog-tile-details .author {
    margin-right: 1rem;
}
.blog-tile .blog-tile-details .tags {
    cursor: pointer;
}
.blog-tile .blog-tile-details .date {
    margin-left: 15px;
}
.blog-tile .blog-tile-details .tags:hover {
    text-decoration: underline;
}
.distinctive-tile {
    width: calc(66% - 2rem);
    margin-right: 2rem;
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    .blog-tile {
        width: calc(50% - 2rem);
        flex-grow: calc(50% - 2rem);
        padding-left: 0 !important;
    }
    .blog-tile:nth-child(odd):not(:nth-child(1)) {
        padding-left: 2rem !important;
    }
    .distinctive-tile {
        width: 100%;
        flex-grow: 100%;
    }
}

@media screen and (max-width: 600px) {
    .blog-tile {
        width: 100%;
        flex-grow: 100%;
        padding-left: 0 !important;
    }
    .distinctive-tile {
        width: 100%;
        flex-grow: 100%;
        margin-right: 0 !important;
    }
    .distinctive-tile .thumbnail {
        height: 250px !important;
    }
}
.cookies-banner {
    position: fixed;
    z-index: 999;
    top: 4rem;
    left: 0;
    width: 100vw;
    display: flex;
    padding: 5px 0;
    font-size: .8rem;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    box-shadow: 0 5px 25px rgba(0,0,0,.15);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    will-change: transform;
}
.cookies-banner * { font-size: .8rem !important; }
.visible {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.cookies-banner a {
    font-weight: 600;
    margin-left: 1rem;
    white-space: nowrap;
}
.cookies-banner div {
    display: inherit;
    align-items: center;
}
.policy-label {
    font-size: .6rem;
    font-weight: 600;
    margin-right: 10rem;
}

@media screen and (max-width: 768px) {
    .cookies-banner {
        padding: 5px 1rem;
    }
    .policy-label {
        margin-right: 1rem;
        line-height: 1.3;
    }
}
@media screen and (max-width: 400px) {
    .cookies-banner div:last-child {
        display: flex;
        flex-flow: row-reverse;
        position: absolute;
        width: 100%;
        right: 1rem;
    }
    .cookies-banner div:last-child .styled-link {
        position: absolute;
        left: 1rem;
        bottom: -1rem;
        display: none;
    }
    .cookies-banner {
        padding: 20px 1rem;
    }
    .cookies-banner .policy-label {
        margin-right: 8rem;
    }
}

.loader-container {
    margin-top: 4rem;
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader{
    width: 50px;
    height: 50px;
    -webkit-animation: heartbeat 1s infinite;
            animation: heartbeat 1s infinite;
}

@-webkit-keyframes heartbeat
{
  0%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  20%
  {
    -webkit-transform: scale( 1 );
            transform: scale( 1 );
  }
  40%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  60%
  {
    -webkit-transform: scale( 1 );
            transform: scale( 1 );
  }
  80%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  100%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
}

@keyframes heartbeat
{
  0%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  20%
  {
    -webkit-transform: scale( 1 );
            transform: scale( 1 );
  }
  40%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  60%
  {
    -webkit-transform: scale( 1 );
            transform: scale( 1 );
  }
  80%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
  100%
  {
    -webkit-transform: scale( .75 );
            transform: scale( .75 );
  }
}
.search-page-container {
    padding: 6rem 3rem;
}
.search-results-container {
    padding: 2rem 0 0 0;
    display: flex;
    flex-flow: row wrap;
}
.latest-articles-container {
    display: flex;
    flex-flow: row nowrap;
}
@media screen and (max-width: 767px) {
    .latest-article-tile {
        width: 100% !important;
        margin: 0 !important;
        margin-top: 10px !important;
    }
    .latest-article-tile h2 {
        padding: 15px 0 15px 0 !important;
    }
    .latest-article-tile .text-container > div {
        padding-bottom: 20px;
        line-height: 1.5 !important;
    }
    .latest-articles-container {
        flex-flow: column;
    }
    .latest-articles-container .latest-article-tile {
        margin-bottom: 40px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/* Latest articles */
.latest-articles-label {
    font-weight: 600;
    margin-bottom: 30px;
}
.latest-article-tile {
    width: 31%;
    margin-bottom: 40px !important;
    cursor: pointer;
}
.latest-article-tile .image-container {
    width: 100%;
    min-height: 250px;
    height: 250px;
    overflow: hidden;
}
.latest-article-tile .image-container img {
    object-fit: cover;
    width: 100%;
    height: 250px;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
}
.latest-article-tile .text-container h2 {
    font-weight: 300;
    padding: 24px 0 14px 0;
}
.latest-article-tile .text-container h2 > * {
    font-weight: 600;
}
.latest-article-tile .text-container .latest-article-tile-h2 > * {
    font-family: 'Eksell', sans-serif !important;
    font-weight: 300 !important;
}
.latest-article-tile .text-container > div > * {
    line-height: 1.5 !important;
}
.latest-article-tile .text-container .article-details {
    font-size: .8rem;
    font-weight: 600;
    margin-top: 10px;
}
.latest-article-tile .text-container .article-details .author {
    margin-right: 1rem;
}
.latest-article-tile .text-container .article-details .tag {
    padding: 0 3px;
    text-transform: capitalize;
}
.latest-article-tile .text-container .article-details .date {
    margin-left: 1rem;
}

@media screen and (min-width: 768px) {
    .latest-article-tile .image-container:hover img {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}
