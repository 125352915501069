.kmk-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    padding: 10rem 2rem;
    background: #FFF;
    transform: translateX(-100%);
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    background-color: #000;
    color: #FFF;
    -webkit-overflow-scrolling: touch;
    transition: transform 400ms ease, opacity 500ms ease;
    transition-delay: 300ms;
}
/* Menu is active */
.kmk-menu-active {
    transform: translateX(0);
}
.kmk-menu-active .kmk-menu-section section {
    transform: translateY(0);
    opacity: 1;
    transition: all 1000ms ease 600ms;
}
.kmk-menu-active .kmk-menu-logo img {
    opacity: 1;
    transition: opacity 600ms ease 500ms;
}
.kmk-menu-logo {
    position: absolute;
    top: 2rem;
    left: 4rem;
}
.kmk-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: opacity 300ms ease;
    animation: fade-in 400ms ease forwards;
}
.kmk-menu-overlay-active {
    opacity: 1;
    z-index: 9998;
}
.kmk-menu .menu-logo {
    width: 120px;
    position: absolute;
    top: 0rem;
    left: 2rem;
}
.kmk-menu .menu-icon {
    width: 33px;
    max-width: 33px;
    height: 33px;
    padding: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.kmk-menu .menu-inner-container {
    display: flex;
    flex-flow: column;
}
.kmk-menu .menu-inner-container div:first-child { margin-bottom: 80px; }
.kmk-menu .menu-inner-container div:nth-child(2) { margin-bottom: 40px; }
.links-container {
    display: flex;
    flex-flow: column;
    font-weight: 400;
    font-size: 18px;
    color: #FFF;
    line-height: 2;
}
.kmk-menu .contact-container label {
    font-weight: 800;
    font-size: 24px;
    margin: 1rem 0;
    display: block;
}
.kmk-menu .contact-container div {
    font-weight: 600;
    line-height: 1.5;
}
.kmk-menu .links-container a[data-active="true"] {
    text-decoration: underline;
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
