.menu-toggle {
    position: fixed;
    /* right: 1.5rem; */
    left: calc(100vw - 64px);
    top: .6em;
    z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
@media screen and (min-width: 1025px) {
    .menu-toggle {
        display: none;
    }
}
.menu-toggle .hamburger {
      position:absolute;
      height:100%;
      width:100%;
}
.menu-toggle .hamburger span {
    width: 100%;
    height: 2px;
    position:relative;
    margin: 8px 0;
    display:block;
    background:#FFF;
    transition: width 300ms ease;
}
.menu-toggle .hamburger span:nth-child(1) {
    transition-delay:.5s;
}
.menu-toggle .hamburger span:nth-child(2) {
    transition-delay:.625s;
}
.menu-toggle .hamburger span:nth-child(3) {
    transition-delay:.75s;
}
.menu-toggle .cross {
    position:absolute;
    height:100%;
    width:100%;
    transform:rotate(45deg);
}
.menu-toggle .cross span {
    background:#000;
    transition: all 300ms ease;
}
.menu-toggle .cross span:nth-child(1) {
    height: 0%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 14px;
    transition-delay:0s;
}
.menu-toggle .cross span:nth-child(2) {
    width: 0%;
    height: 2px;
    position:absolute;
    left: 0;
    top: 14px;
    transition-delay:.25s;
}

.menu-toggle.open .hamburger span {
    width:0%;
}
.menu-toggle.open .hamburger span:nth-child(1) {
    transition-delay:0s;
}
.menu-toggle.open .hamburger span:nth-child(2) {
    transition-delay:.125s;
}
.menu-toggle.open .hamburger span:nth-child(3) {
    transition-delay:.25s;
}
.menu-toggle.open .cross span:nth-child(1) {
    height: 100%;
    background-color: #FFF;
    transition-delay:.625s;
}
.menu-toggle.open .cross span:nth-child(2) {
    width: 100%;
    background-color: #FFF;
    transition-delay:.375s;
}
.menu-toggle.inverted .hamburger span {
    background: #000;
}

@media screen and (max-width: 768px) {
    .menu-toggle {
        left: calc(100vw - 50px);
    }
}
