.cookies-banner {
    position: fixed;
    z-index: 999;
    top: 4rem;
    left: 0;
    width: 100vw;
    display: flex;
    padding: 5px 0;
    font-size: .8rem;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    box-shadow: 0 5px 25px rgba(0,0,0,.15);
    transform: translateY(-100%);
    transition: transform 400ms ease-in-out;
    will-change: transform;
}
.cookies-banner * { font-size: .8rem !important; }
.visible {
    transform: translateY(0);
}
.cookies-banner a {
    font-weight: 600;
    margin-left: 1rem;
    white-space: nowrap;
}
.cookies-banner div {
    display: inherit;
    align-items: center;
}
.policy-label {
    font-size: .6rem;
    font-weight: 600;
    margin-right: 10rem;
}

@media screen and (max-width: 768px) {
    .cookies-banner {
        padding: 5px 1rem;
    }
    .policy-label {
        margin-right: 1rem;
        line-height: 1.3;
    }
}
@media screen and (max-width: 400px) {
    .cookies-banner div:last-child {
        display: flex;
        flex-flow: row-reverse;
        position: absolute;
        width: 100%;
        right: 1rem;
    }
    .cookies-banner div:last-child .styled-link {
        position: absolute;
        left: 1rem;
        bottom: -1rem;
        display: none;
    }
    .cookies-banner {
        padding: 20px 1rem;
    }
    .cookies-banner .policy-label {
        margin-right: 8rem;
    }
}
