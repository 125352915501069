.policy-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 1000px;
    min-height: 300px;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 35px 44px;
    z-index: 1000;
    color: #000 !important;
    animation: fade-in 500ms ease forwards;
}
.policy-popup-container h2 {
    font-family: 'Eksell', serif !important;
    font-weight: 300 !important;
    margin-bottom: 22px;
    font-size: 24px;
}
.policy-popup-container p {
    font-size: 14px;
    line-height: 1.8;
}
.policy-popup-container .close-popup {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .policy-popup-container {
        min-width: unset;
        width: 95vw;
    }
}
@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}