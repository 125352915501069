.blog-page {
    padding: 6rem 2rem;
}
.blog-tile {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    width: calc(33% - 2rem);
    margin-bottom: 2rem;
}
.blog-tile .thumbnail {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-color: #F4F5F7;
}
.blog-tile:not(:nth-child(2)) {
    padding-left: 2rem;
}
.blog-tile h1 {
    margin-top: 2rem;
}
.blog-tile h2 {
    margin-top: 1rem;
    line-height: 1;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
}
.blog-tile p {
    font-size: 1rem;
    font-weight: 400;
    max-height: 300px;
    margin: 1rem 0;
}
.blog-tile .blog-tile-details {
    margin-top: 1rem;
    font-weight: 800;
    font-size: .8rem;
}
.blog-tile .blog-tile-details .author {
    margin-right: 1rem;
}
.blog-tile .blog-tile-details .tags {
    cursor: pointer;
}
.blog-tile .blog-tile-details .date {
    margin-left: 15px;
}
.blog-tile .blog-tile-details .tags:hover {
    text-decoration: underline;
}
.distinctive-tile {
    width: calc(66% - 2rem);
    margin-right: 2rem;
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    .blog-tile {
        width: calc(50% - 2rem);
        flex-grow: calc(50% - 2rem);
        padding-left: 0 !important;
    }
    .blog-tile:nth-child(odd):not(:nth-child(1)) {
        padding-left: 2rem !important;
    }
    .distinctive-tile {
        width: 100%;
        flex-grow: 100%;
    }
}

@media screen and (max-width: 600px) {
    .blog-tile {
        width: 100%;
        flex-grow: 100%;
        padding-left: 0 !important;
    }
    .distinctive-tile {
        width: 100%;
        flex-grow: 100%;
        margin-right: 0 !important;
    }
    .distinctive-tile .thumbnail {
        height: 250px !important;
    }
}