.cta-carousel-container {
    min-height: 550px;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}
.cta-carousel-container .cta-carousel-section {
    height: 600px;
    min-height: 600px;
    width: 50%;
    flex-grow: 2;
    flex-basis: 50%;
}
.cta-carousel-additionaltext {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 4rem;
    font-family: 'PT Sans', sans-serif !important;
}
.cta-carousel-container .cta-carousel-section h2 {
    cursor: pointer;
    font-size: 3.05rem;
    font-weight: 300;
    font-family: 'Eksell', sans-serif !important;
    transition: all 700ms ease;
}
.cta-carousel-container button:first-of-type {
    margin-top: 2rem;
}
.cta-carousel-container button {
    padding: 1rem 2rem;
    border-radius: 2rem;
    font-size: 1rem;
}
.cta-carousel-container .image-section {
    padding: 0;
    width: 50px;
    overflow: hidden;
}
.cta-carousel-container .centered-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: inherit;
    min-height: inherit;
}
.cta-carousel-container .centered-section div {
    padding: 2rem;
}
.cta-carousel-container .images-strip img {
    width: inherit;
    object-fit: cover;
    height: inherit;
    min-height: inherit;
}
.cta-carousel-container .images-strip {
    display: flex;
    flex-flow: row nowrap;
    height: inherit;
    transition: transform 1s ease;
}

@media screen and (max-width: 768px) {
    .cta-carousel-container {
        flex-flow: column;
    }
    .cta-carousel-container .cta-carousel-section {
        width: 100%;
        flex-basis: 100%;
    }
    .cta-carousel-container .images-strip img {
        min-width: 100vw;
    }
}
@media screen and (min-width: 1024px) {
    .cta-carousel-container .images-strip img {
        min-width: 50vw;
    }
}