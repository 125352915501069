.logo-carousel-container {
    padding: 4rem 2rem; 
    width: 100%;
    max-width: 100vw;
}
.logo-carousel-container h2 {
    font-family: 'Eksell', sans-serif !important;
    margin-bottom: 2rem;
    font-size: 3.05rem;
    text-align: center;
}
.logo-carousel-container .carousel-element {
    background: #FFF;
    width: 170px;
    max-width: 170px;
    height: 100px;
    max-height: 100px;
}
.logo-carousel-container .carousel-element img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .logo-carousel-container {
        padding: 2rem 0;
    }
    .logo-carousel-container h2 {
        padding: 0 2rem;
    }
}