.page-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 800px;
    min-height: 400px;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 35px 44px;
    z-index: 1000;
    color: #000 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: fade-in 500ms ease forwards;
}
.page-popup-container h2 {
    font-size: 50px;
    color: #FFF;
    font-family: 'Eksell', serif !important;
}
.page-popup-container button {
    opacity: 0;
    animation: fade-in 400ms ease forwards;
}
.page-popup-container button, .page-popup-container h2 {
    position: absolute;
}
.page-popup-container .close-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .page-popup-container {
        width: 90vw;
        min-height: 600px;
        min-width: unset;
        background-size: cover;
        background-position: 85%;
    }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}